/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import Logo from "../../images/logo1fd.jpeg";
import LogoDark from "../../images/logo1fd.jpeg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const EmailVerification = () => {
  const { token } = useParams();
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const API_BASE = "https://purnaagents.com/api";

  // console.log(token);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Send request to the backend to verify email
        const response = await axios({
          method: "get",
          url: API_BASE + `/emailVerification/${token}`,
        });

        // console.log(response);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setMessage(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setErr("Failed to verify email.");
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  if (isLoading) {
    return null;
  }

  return (
    <React.Fragment>
      <Head title="Success" />
      <ToastContainer />
      <PageContainer>
        {message ? (
          <Block className="nk-block-middle nk-auth-body">
            <div className="brand-logo pb-5">
              <Link to={`/`} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
              </Link>
            </div>
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Thank you for registration</BlockTitle>
                <BlockDes className="text-success">
                  <p>Your email is verified, now sign in with your username & password sent to mail</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
          </Block>
        ) : (
          <Block className="nk-block-middle nk-auth-body">
            <div className="brand-logo pb-5">
              <Link to={`/`} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
              </Link>
            </div>
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Failed Email Verification</BlockTitle>
                <BlockDes className="text-success">
                  <p>{err}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
          </Block>
        )}
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default EmailVerification;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon, Row, Col } from "../../../components/Component";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";

const GlobalStats = () => {
  const [copy, setCopy] = useState(false);
  const [global, setGlobal] = useState({});

  // useEffect(() => {

  //     (async () => {

  //         let resp = await axios.get('https://purnaagents.com/api/getGlobalStats')
  //         setGlobal(resp['data']['data'])

  //     })()

  // }, [])

  const onCopyClick = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
  };

  return (
    <Row>
      <Col lg="6">
        <Card
          className="card-bordered"
          style={{ marginBottom: "25px", marginTop: "20px", height: "80%" }}
        >
          <div className="nk-refwg">
            <div className="nk-refwg-invite card-inner">
              <div className="nk-refwg-head g-3">
                <div className="nk-refwg-title">
                  <h5 className="title">Refer &amp; Earn</h5>
                  <div className="title-sub">
                    Use the bellow link to invite your friends.
                  </div>
                </div>
              </div>
              <div className="nk-refwg-url">
                <div
                  className={`form-control-wrap ${
                    copy ? "clipboard-success" : ""
                  }`}
                >
                  <CopyToClipboard
                    className="form-clip clipboard-init"
                    text={`http://purnaagents.com/auth-register?ref=${localStorage.getItem(
                      "userId"
                    )}`}
                    onCopy={onCopyClick}
                  >
                    <div>
                      <Icon name="copy" className="clipboard-icon"></Icon>
                      <span className="clipboard-text">
                        {copy ? "Copied" : "Copy"}
                      </span>
                    </div>
                  </CopyToClipboard>
                  <div className="form-icon">
                    <Icon name="link-alt"></Icon>
                  </div>
                  <input
                    type="text"
                    className="form-control copy-text"
                    id="refUrl"
                    defaultValue={`http://purnaagents.com/auth-register?ref=${localStorage.getItem(
                      "userId"
                    )}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Col>

      {/* <Col lg="3">
        <Card
          className="card-bordered"
          inverse
          color="#2c3782y"
          style={{
            marginBottom: "25px",
            marginTop: "20px",
            backgroundColor: "#2c3782",
            height: "80%",
          }}
        >
          <CardBody className="card-inner">
            <CardTitle tag="h6">
              Global <br /> Registrations
            </CardTitle>
            <CardText className="fw-bold fs-16px">
              {global && global["totalRegistrations"]}
            </CardText>
          </CardBody>
        </Card>
      </Col> */}

      {/* <Col lg="3">
        <Card
          className="card-bordered"
          inverse
          color="#2c3782y"
          style={{
            marginBottom: "25px",
            marginTop: "20px",
            backgroundColor: "#2c3782",
            height: "80%",
          }}
        >
          <CardBody className="card-inner">
            <CardTitle tag="h6">
              Global <br /> Stakes
            </CardTitle>
            <CardText className="fw-bold fs-16px">
              {global && global["totalStake"]} USD
            </CardText>
          </CardBody>
        </Card>
      </Col> */}
    </Row>
  );
};

export default GlobalStats;

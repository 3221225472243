/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  LoginLogTable,
  Button,
} from "../../../components/Component";
// import { loginData } from "../../../components/table/TableData";
import axios from "axios";
import moment from "moment";

const UserProfileActivityPage = ({ sm, updateSm }) => {
  const [loginData, setLoginData] = useState([]);

  const API_Base_Url = "https://purnaagents.com/api";
  // console.log(kycData);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          method: "get",
          url: API_Base_Url + "/log/user/log",
          withCredentials: true,
        });

        // console.log(response);
        if (response.data.status === true) {
          setLoginData(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Login Activity</BlockTitle>
            <BlockDes>
              <p>
                Here is your last 10 login activities log.{" "}
                <span className="text-soft">
                  <Icon name="info" />
                </span>
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <table className="table table-ulogs">
        <thead className="thead-light">
          <tr>
            <th className="tb-col-os">
              <span className="overline-title">
                Browser <span className="d-sm-none">/ IP</span>
              </span>
            </th>
            <th className="tb-col-ip">
              <span className="overline-title">IP</span>
            </th>
            <th className="tb-col-time">
              <span className="overline-title">Time</span>
            </th>
            <th className="tb-col-action">
              <span className="overline-title">&nbsp;</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {loginData.map((item, idx) => {
            return (
              <tr key={idx}>
                <td className="tb-col-os">{item.browser}</td>
                <td className="tb-col-ip">
                  <span className="sub-text">{item.ipAddress}</span>
                </td>
                <td className="tb-col-time">
                  <span className="sub-text">
                    {moment(item.createdAt).format("M/D/YY h:mm")}
                    {/* <span className="d-none d-sm-inline-block">
                      {item.time}
                    </span> */}
                  </span>
                </td>
                <td className="tb-col-action">
                  {item.action === true && (
                    <a
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="link-cross mr-sm-n1"
                    >
                      <Icon name="cross"></Icon>
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <LoginLogTable /> */}
    </React.Fragment>
  );
};
export default UserProfileActivityPage;

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Logo1 from "../../images/logo1fd.jpeg";
import LogoDark from "../../images/logo1fd.jpeg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";

import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";

import { Form, FormGroup, Spinner, Alert, UncontrolledAlert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import { useRecoilState } from "recoil";
import { userState } from "../../atom";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useRecoilState(userState);

  const onDismiss = () => {
    setError(null);
  };

  const onFormSubmit = async (formData) => {
    try {
      setLoading(true);
      let resp = await axios({
        method: "post",
        url: "https://purnaagents.com/api/login",
        data: {
          username: formData.username,
          password: formData.passcode,
        },
        withCredentials: true,
      });
      // console.log(resp)

      if (resp.data.status === true) {
        setSuccess(true);
        localStorage.setItem("accessToken", resp["data"]["token"]);
        localStorage.setItem("userId", resp.data.data._id);
        setUserData(resp.data.data);
        setTimeout(() => {
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
          );
          window.location.reload();
        }, 2000);
      } else {
        setTimeout(() => {
          setError("Cannot login with credentials");
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      setError("unable to login with credintials");
    }
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo1}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>

          <PreviewCard
            className="card-bordered this-card-sucks"
            bodyClass="card-inner-lg"
          >
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
                <BlockDes>
                  <p>
                    Access Purnaagents Account with using your User Id and Password.
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal ? (
              <div className="mb-3">
                <UncontrolledAlert
                  color="danger"
                  toggle={onDismiss}
                  className="alert-icon"
                >
                  {" "}
                  <Icon name="alert-circle" /> Unable to login with credentials
                </UncontrolledAlert>
              </div>
            ) : success ? (
              <div className="mb-3">
                <Alert color="success" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> SuccessFully Logged In
                </Alert>
              </div>
            ) : null}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    User Name
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="username"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your User Name"
                    className="form-control-lg form-control"
                  />
                  {errors.username && (
                    <span className="invalid">{errors.username.message}</span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Passcode
                  </label>
                  <Link
                    className="link link-primary link-sm"
                    to={`${process.env.PUBLIC_URL}/auth-reset`}
                  >
                    Forgot Code?
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>
                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="passcode"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your word"
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  {errors.passcode && (
                    <span className="invalid">{errors.passcode.message}</span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              New on our platform?{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth-register`}>
                Create an account
              </Link>
            </div>
            {/* <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span></span>
              </h6>
            </div> */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;

import React from "react";
import Logo from "../../images/logo1fd.jpeg";
import LogoDark from "../../images/logo1fd.jpeg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, PreviewAltCard } from "../../components/Component";
import { Link, useLocation } from "react-router-dom";
import { Button } from "reactstrap";


const Success = () => {

  const location = useLocation();
  const { CustID, Name, Password } = location.state.data;


  return (
    <React.Fragment>
      <Head title="Success" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Thank you for Registering With Us</BlockTitle>
              <BlockDes className="text-success">
                <p>You can now sign in with your new password</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>

          <PreviewAltCard className='d-flex flex-column'>

            <div className="d-flex justify-content-around align-center">
              <strong> Your customer ID </strong>
              <span> {CustID && CustID} </span>
            </div>

            <div className="d-flex justify-content-around align-center">
              <strong> username </strong>
              <span> {Name && Name} </span>
            </div>
            <div className="d-flex justify-content-around align-center">
              <strong> password </strong>
              <span> {Password && Password}</span>
            </div>

          </PreviewAltCard>
          <br />
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <Button color="primary" size="lg">
              LoginNow
            </Button>
          </Link>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Success;

import React, { useEffect, useState } from 'react'
import Head from '../../../layout/head/Head'
import Content from '../../../layout/content/Content'
import { PreviewAltCard, Row, Col } from '../../../components/Component'
import { Button, Form, FormGroup, Card, CardText, CardBody, Spinner } from 'reactstrap'
import { useForm } from "react-hook-form";
import DataTable from 'react-data-table-component';
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import './table.css'
import { isoToHumanRedable, toastError, toastSuccess } from '../../../utils/Utils'

const P2pDepositToDeposit = () => {
    const { errors, register, handleSubmit, reset } = useForm();
    const [dep, setDep] = useState(null)
    const [earn, setEarn] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    let [sponser, setSponser] = useState(null)

    const columns = [
        {
            name: '#trId',
            selector: row => row.TrID,
        },
        {
            name: 'date',
            selector: row => isoToHumanRedable(row.dot),
        },
        {
            name: 'debit',
            selector: row => row.Debit,
        },
        {
            name: 'description',
            selector: row => row.Description,
            wrap: true

        },
        {
            name: 'status',
            selector: row => row.Status,
        },
    ];

    const transferNow = async (e) => {

        try {

            setLoading(true)
            reset()
            let { data } = await axios({
                method: 'post',
                url: 'https://purnaagents.com/api/transferFundDigimint',
                data: {
                    CusID: localStorage.getItem('custId'),
                    anotherCustid: e['recieverId'],
                    totalDigi: e['totalDigi'],
                    type: 3
                }
            })

            if (data.result === 'SUCCESS') {
                toastSuccess(data.message)
            }
            setLoading(false)


        } catch (error) {
            setLoading(false)
            let { message } = error.response.data
            toastError(message)
        }

    }

    const getUser = async (e) => {

        try {

            let { data } = await axios.post('https://purnaagents.com/api/checkSponser', { sponserId: e })
            setSponser(data.sponserName)

        } catch (error) {
            toastError(error.message)
        }

    }

    useEffect(() => {

        (async () => {

            try {
                let { data } = await axios.post("https://purnaagents.com/api/getDepositeBalance", { userId: localStorage.getItem("custId"), })
                setDep(data['depositeBalance'])

                let respOfAxios = await axios.post('https://purnaagents.com/api/getEarnWalletBalance', { userId: localStorage.getItem("custId"), })
                setEarn(respOfAxios.data["incomeWalletBal"])

                let resp = await axios.post('https://purnaagents.com/api/getTransferDetails', { custId: localStorage.getItem("custId") })
                setData(resp['data']['data'])

            } catch (error) {
                toastError(error.response.data.message)
            }

        })()


    }, [loading])

    return (
        <React.Fragment>
            <Head title="My Referrals" />
            <ToastContainer />
            <Content>
    
                <Row className="g-gs">
                    <Col md="2">

                    </Col>
                    <Col md="4" lg="4" sm='8' xxl='4'>
                        <Card className="card-bordered">
                            <CardBody className="card-inner d-flex flex-row align-items-center justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="100px" height="70px">
                                    <rect
                                        width="55"
                                        height="39"
                                        x="9"
                                        y="21"
                                        fill="#fff"
                                        stroke="#6576ff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        rx="6"
                                        ry="6"
                                    ></rect>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M17 44L25 44"
                                    ></path>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M30 44L38 44"
                                    ></path>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M42 44L50 44"
                                    ></path>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M17 50L36 50"
                                    ></path>
                                    <rect width="15" height="8" x="16" y="31" fill="#c4cefe" rx="1" ry="1"></rect>
                                    <path
                                        fill="#fff"
                                        stroke="#6576ff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M76.79 72.87L32.22 86.73a6 6 0 01-7.47-4L17 57.71a6 6 0 014-7.51l44.52-13.86a6 6 0 017.48 4l7.73 25.06a6 6 0 01-3.94 7.47z"
                                    ></path>
                                    <path fill="#6576ff" d="M75.27 47.3L19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3z"></path>
                                    <path
                                        fill="#c4cefe"
                                        d="M30 77.65l-1.9-6.79a1 1 0 01.69-1.23l4.59-1.3a1 1 0 011.23.7l1.9 6.78a1 1 0 01-.67 1.19l-4.59 1.3a1 1 0 01-1.25-.65zM41.23 74.48l-1.9-6.78a1 1 0 01.67-1.23l4.58-1.3a1 1 0 011.23.69l1.9 6.78a1 1 0 01-.71 1.24l-4.58 1.29a1 1 0 01-1.19-.69zM52.43 71.32l-1.9-6.79a1 1 0 01.69-1.23l4.59-1.3a1 1 0 011.19.7l1.9 6.78a1 1 0 01-.69 1.23L53.66 72a1 1 0 01-1.23-.68z"
                                    ></path>
                                    <ellipse
                                        cx="55.46"
                                        cy="19.1"
                                        fill="#fff"
                                        stroke="#6576ff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        rx="16.04"
                                        ry="16.1"
                                    ></ellipse>
                                    <ellipse cx="55.46" cy="19.1" fill="#e3e7fe" rx="12.11" ry="12.16"></ellipse>
                                    <text
                                        fill="#6576ff"
                                        fontFamily="Nunito-Black, Nunito Black"
                                        fontSize="16.12"
                                        transform="matrix(.99 0 0 1 50.7 23.72)"
                                    >
                                        $
                                    </text>
                                </svg>

                                <CardText>
                                    <h5>Income Wallet Balance</h5>
                                    <h6> {earn > 0 ? earn : 0} USD</h6>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="4" lg="4" sm='8' xxl='4' >
                        <Card className="card-bordered">
                            <CardBody className="card-inner d-flex flex-row align-items-center justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="100px" height="70px">
                                    <rect
                                        width="55"
                                        height="39"
                                        x="9"
                                        y="21"
                                        fill="#fff"
                                        stroke="#6576ff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        rx="6"
                                        ry="6"
                                    ></rect>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M17 44L25 44"
                                    ></path>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M30 44L38 44"
                                    ></path>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M42 44L50 44"
                                    ></path>
                                    <path
                                        fill="none"
                                        stroke="#c4cefe"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M17 50L36 50"
                                    ></path>
                                    <rect width="15" height="8" x="16" y="31" fill="#c4cefe" rx="1" ry="1"></rect>
                                    <path
                                        fill="#fff"
                                        stroke="#6576ff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M76.79 72.87L32.22 86.73a6 6 0 01-7.47-4L17 57.71a6 6 0 014-7.51l44.52-13.86a6 6 0 017.48 4l7.73 25.06a6 6 0 01-3.94 7.47z"
                                    ></path>
                                    <path fill="#6576ff" d="M75.27 47.3L19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3z"></path>
                                    <path
                                        fill="#c4cefe"
                                        d="M30 77.65l-1.9-6.79a1 1 0 01.69-1.23l4.59-1.3a1 1 0 011.23.7l1.9 6.78a1 1 0 01-.67 1.19l-4.59 1.3a1 1 0 01-1.25-.65zM41.23 74.48l-1.9-6.78a1 1 0 01.67-1.23l4.58-1.3a1 1 0 011.23.69l1.9 6.78a1 1 0 01-.71 1.24l-4.58 1.29a1 1 0 01-1.19-.69zM52.43 71.32l-1.9-6.79a1 1 0 01.69-1.23l4.59-1.3a1 1 0 011.19.7l1.9 6.78a1 1 0 01-.69 1.23L53.66 72a1 1 0 01-1.23-.68z"
                                    ></path>
                                    <ellipse
                                        cx="55.46"
                                        cy="19.1"
                                        fill="#fff"
                                        stroke="#6576ff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        rx="16.04"
                                        ry="16.1"
                                    ></ellipse>
                                    <ellipse cx="55.46" cy="19.1" fill="#e3e7fe" rx="12.11" ry="12.16"></ellipse>
                                    <text
                                        fill="#6576ff"
                                        fontFamily="Nunito-Black, Nunito Black"
                                        fontSize="16.12"
                                        transform="matrix(.99 0 0 1 50.7 23.72)"
                                    >
                                        $
                                    </text>
                                </svg>

                                <CardText>
                                    <h5>Deposit Wallet Balance</h5>
                                    <h6> {dep > 0 ? dep : 0} USD</h6>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="2">

                    </Col>

                </Row>





                <Row className="g-gs">
                    <Col md="2">
                    </Col>

                    <Col md="8">
                        <PreviewAltCard className="card-full d-flex">
                            <Form className="is-alter" onSubmit={handleSubmit(transferNow)}>
                                <FormGroup>
                                    <label className="form-label">Transferred to User ID</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="recieverId"
                                        placeholder="Enter recieverId"
                                        ref={register({ required: "This field is required" })}
                                        onChange={(e) => getUser(e.target.value)}
                                    />
                                    {errors.recieverId && <span className="invalid">{errors.recieverId.message}</span>}
                                </FormGroup>

                                {

                                    sponser ? <FormGroup>
                                        <label className="form-label"> {sponser && sponser} </label>
                                    </FormGroup>
                                        :
                                        null

                                }


                                <FormGroup>
                                    <FormGroup>
                                        <label className="form-label">Amount to be Transferred</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="totalDigi"
                                            placeholder="amount to be transfer"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.totalDigi && <span className="invalid">{errors.totalDigi.message}</span>}
                                    </FormGroup>
                                </FormGroup>

                                {/* <FormGroup>
                                    <FormGroup>
                                        <label className="form-label">Transaction Passsword</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="enter transaction password"
                                            ref={register({ required: "This field is required" })}
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>

                                </FormGroup> */}
                                <FormGroup>

                                    {
                                        loading ?
                                            <Button color='primary'>

                                                please wait <Spinner size='sm' />

                                            </Button> :
                                            <Button color='primary'>
                                                P2P Fund Transfer Now
                                            </Button>
                                    }

                                </FormGroup>
                            </Form>
                        </PreviewAltCard>
                    </Col>
                    <Col md="2">
                    </Col>
                </Row>

                <Row className="g-gs">
                    <Col md="2">
                    </Col>
                    <Col md='8'>
                        <div style={{ backgroundColor: 'white' }}>

                            {
                                data && <DataTable
                                    title="Transfer Records"
                                    direction="auto"
                                    pagination
                                    responsive
                                    columns={columns}
                                    data={data && data}
                                />
                            }

                        </div>

                    </Col>
                    <Col md="2">
                    </Col>
                </Row>

            </Content>
        </React.Fragment>
    )
}

export default P2pDepositToDeposit
import React, { useEffect, useState } from "react";
import Head from "../../../../../layout/head/Head";
import { isoToHumanRedable, toastError } from "../../../../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import { PaginationComponent, DataTable } from "../../../../../components/Component";

import axios from "axios";
const DailyStakingHistoryTable = ({ props }) => {

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(10);

    useEffect(() => {

        (async () => {

            try {

                const resp = await toast.promise(
                    axios.post("https://purnaagents.com/api/dailyStakingHistoryTable", { custId: localStorage.getItem('custId') }),
                    {
                        pending: 'Fetching Direct Referral',
                        success: 'Data Fetched',
                        error: 'failed 🤯'
                    },
                    {
                        autoClose: 1000
                    }
                )
                console.log(resp['data']['data'])
                setData(resp['data']['data'])

            } catch (error) {
                let { message } = error['response']['data']
                toastError(message)
            }

        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setData])


    // function to set the action to be taken in table header

    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <React.Fragment>

            <Head title="User List - Regular"></Head>
            <ToastContainer />
            <DataTable className="card-stretch this-card-sucks-1">
                <table className="table table-hover table-bordered  table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col"> Tr Id </th>
                            <th scope="col"> Credit </th>
                            <th scope="col">Debit</th>
                            <th scope="col">Date</th>
                            <th scope="col"> Description </th>
                        </tr>
                    </thead>
                    <tbody>

                        {data && currentItems.map((e, i) => (
                            <tr key={i}>
                                <th scope="row">{e.txno}</th>
                                <td>{e.credit}</td>
                                <td>{e.debit}</td>
                                <td>{e.dot && isoToHumanRedable(e.dot)}</td>
                                <td>{e.description}</td>
                                {/* <td style={{ color: `${e.Status === 'Active' ? 'green' : 'red'}` }}>{e.Status}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="card-inner">
                    {currentItems.length > 0 ? (
                        <PaginationComponent
                            itemPerPage={itemPerPage}
                            totalItems={data.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    ) : (
                        <div className="text-center">
                            <span className="text-silent">{data && data.length <= 0 ? '0 records Found' : null}</span>
                        </div>
                    )}
                </div>
            </DataTable>

        </React.Fragment>
    );
};
export default DailyStakingHistoryTable;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
  RSelect,
  Icon,
} from "../components/Component";
import { Link } from "react-router-dom";
import handleSignout from "./auth/HandleSignout";
import { depositBalanceState, incomeBalanceState } from "../atom";

const Wallet = () => {
  const [depositWallet, setDepositWallet] = useRecoilState(depositBalanceState);
  const [incomeWallet, setIncomeWallet] = useRecoilState(incomeBalanceState);
  const [sm, updateSm] = useState(false);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);

  let API_BASE = "http://localhost:4000/api";
  //   console.log(depositWallet);

  useEffect(() => {
    // getUserPacakageData();
  }, []);

  //Get user pacakage Data
  const getUserPacakageData = async () => {
    try {
      const result = await axios({
        method: "get",
        // url: API_BASE + `/wallet/userPacakage/${userId}`,
        withCredentials: true,
      });

      // console.log(result);
      if (result.data.status === true) {
        // setUserPacakageData(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   if (!loading) {
  //     return null;
  //   }

  return (
    <React.Fragment>
      <Head title="Chips Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h6">All Wallets</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            {/* <Col md="12">
              <PreviewAltCard className="card-full card-bordered">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="title">Chips Statics</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="chips-statics"
                      text="Chips Statics"
                    ></TooltipComponent>
                  </div>
                </div>
                <div>
                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="subtitle">
                          Total Withdrawal
                          <TooltipComponent
                            iconClass="card-hint"
                            icon="help-fill"
                            direction="right"
                            id="actual-withdrawal"
                            text=" Bonus withdrawal"
                          ></TooltipComponent>
                        </div>
                        <span className="amount">
                          {userPacakageData &&
                            Number(userPacakageData.releaseAmount)}
                        </span>
                      </div>
                      <div className="invest-data-history">
                        <div className="subtitle">Remaining Withdrawal</div>
                        <span className="amount">
                          {Number(remainingWithdrawableBalance).toFixed(0)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="d-flex justify-content-end">
                  <Link to={`/pkgHistory`}>Pacakage History</Link>
                </span>
              </PreviewAltCard>
            </Col> */}
            <Col md="4">
              <PreviewAltCard className="card-full card-bordered">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Deposit</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-deposit"
                      text="Total Deposited"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <Link
                    to={{
                      pathname: `/withdrawReq`,
                      state: { balance: depositWallet.balance },
                    }}
                    className="nk-menu-link"
                  >
                    <span className="amount">
                      {Number(depositWallet.balance)}
                    </span>
                  </Link>
                </div>
                <span className="d-flex justify-content-end">
                  {/* <Link to={`/addfundTrxHistory/${depositWallet._id}`}>
                    Deposit History
                  </Link> */}
                </span>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full card-bordered">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Income</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-withdraw"
                      text="Total Income"
                    ></TooltipComponent>
                  </div>
                </div>

                <div className="card-amount">
                  <Link
                    to={{
                      pathname: `/withdrawReq`,
                    }}
                    className="nk-menu-link"
                  >
                    <span className="amount">
                      {Number(incomeWallet.balance).toFixed(2)}
                    </span>
                  </Link>
                </div>
                {/* <RSelect
                  options={options}
                  placeholder="Select Transfer Method"
                  onChange={(e) => {
                    if (e.value === "P2P") {
                      return history.push({
                        pathname: `/p2pTransfer`,
                        state: { balance: Number(incomeWallet.balance), walletId: incomeWallet._id },
                      });
                    }
                     else {
                      return history.push({
                        pathname: `/1ToOtherTransfer`,
                        state: { balance: Number(incomeWallet.balance), walletId: incomeWallet._id },
                      });
                    }
                  }}
                /> */}

                {/* <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">This Month</div>
                      <div className="amount">
                        2,940.59 <span className="currency currency-usd">USD</span>
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">This Week</div>
                      <div className="amount">
                        1,259.28 <span className="currency currency-usd">USD</span>
                      </div>
                    </div>
                  </div>
               
                </div> */}
              </PreviewAltCard>
            </Col>

            {/* <Col md="4">
              <PreviewAltCard className="card-full card-bordered">
                <div className="card-title-group align-start ">
                  <div className="card-title">
                    <h6 className="subtitle">Bonus Chips</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-balance"
                      text="Total Bonus"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <Link
                    to={{
                      pathname: `/withdraw`,
                      state: {
                        balance: Number(commisionWallet.balance),
                        withdrawableAmount:
                          userPacakageData &&
                          userPacakageData.withdrawalableAmount,
                      },
                    }}
                    className="nk-menu-link"
                  >
                    <span className="amount">
                      {Number(commisionWallet.balance).toFixed(2)}
                      <span className="currency currency-usd">BUSD</span>
                    </span>
                  </Link>
                </div>
                <span className="d-flex justify-content-end">
                  <Link to={`/getBonusTrxHistory`}>Bonus History</Link>
                </span>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="subtitle">Self Bonus</div>
                      <div className="amount">
                        {Number(totalSelfBonus).toFixed(2)}
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="subtitle">Team Bonus</div>
                      <div className="amount">
                        {Number(totalTeamBonus).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </PreviewAltCard>
            </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Wallet;

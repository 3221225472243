/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../atom";
import axios from "axios";

const auth = localStorage.getItem("accessToken");

const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  const [userData, setUserData] = useRecoilState(userState);
  // const [auth, setAuth] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const API_Base_Url = "https://purnaagents.com/api";

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Send a request to the server to check if the user is authenticated
        const response = await axios({
          method: "get",
          url: API_Base_Url + "/check-authentication",
          withCredentials: true,
        });

        // If the response indicates successful authentication
        if (response.status === 200) {
          setAuthenticated(true);
        }
      } catch (error) {
        // If there is an error or the response indicates unauthorized access
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    // checkAuthentication();
  }, []);

  // console.log(userData);

  return (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        auth ? (
          <Component {...props} {...rest}></Component>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
        )
      }
    ></Route>
  );
};

export default PrivateRoute;

/* eslint-disable no-unused-vars */
import { atom, selector } from "recoil";
import axios from "axios";
const API_BASE = "https://purnaagents.com/api";
let userId = localStorage.getItem("userId");

export const userState = atom({
  key: "userState",
  default: null,
});

export const depositBalanceState = atom({
  key: "depositBalanceState",
  default: selector({
    key: "depositBalanceState/default",
    get: async () => {
      // Fetch the default amount from the database or any other source
      const response = await axios({
        method: "get",
        url: API_BASE + `/wallet/user/1`,
        withCredentials: true,
      });
      const data = response.data.data;
      return data;
    },
  }),
});

export const incomeBalanceState = atom({
  key: "incomeBalanceState",
  default: selector({
    key: "incomeBalanceState/default",
    get: async () => {
      // Fetch the default amount from the database or any other source
      const response = await axios({
        method: "get",
        url: API_BASE + `/wallet/user/2`,
        withCredentials: true,
      });
      const data = response.data.data;
      return data;
    },
  }),
});

export const userBankState = atom({
  key: "userBankState",
  default: selector({
    key: "userBankState/default",
    get: async () => {
      try {
        // Fetch the default amount from the database or any other source
        const response = await axios({
          method: "get",
          url: API_BASE + `/kyc/bankDetails`,
          withCredentials: true,
        });
        // console.log(response);

        if (response.data.status === true) {
          const data = response.data.data;
          return data;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
      }
    },
  }),
});

export const userKycState = atom({
  key: "userKycState",
  default: selector({
    key: "userKycState/default",
    get: async () => {
      try {
        // Fetch the default amount from the database or any other source
        const response = await axios({
          method: "get",
          url: API_BASE + `/kyc/kycData`,
          withCredentials: true,
        });
        if (response.data.status === true) {
          const data = response.data.data;
          return data;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
      }
    },
  }),
});

import React from "react";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Block,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Usertable from "./components/Usertable";
import { Row, Col } from "reactstrap";
import { PreviewAltCard, TooltipComponent } from "../../components/Component";
import { useState } from "react";

const Directref = ({ ...props }) => {
  const [count, setCount] = useState(null);
  const [activecount, setActiveCount] = useState(null);
  const [inactive, setInactive] = useState(null);

  const getCount = (e) => {
    setCount(e);
  };

  const getActiveCount = (x) => {
    setActiveCount(x);
  };

  const getInactiveCount = (y) => {
    setInactive(y);
  };

  return (
    <React.Fragment>
      <Head title="My Referrals" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h6">Direct Referrals</BlockTitle>
              <BlockDes className="text-soft">
                <p>my direct referrals</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row className="g-gs">
          <Col md="4" className="mb-5">
            <PreviewAltCard className="card-full this-card-sucks">
              <div className="card-title-group align-start mb-0">
                <div className="card-title">
                  <h6 className="subtitle">Total Team</h6>
                </div>
                <div className="card-tools">
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id="invest-deposit"
                    text="Total Team"
                  ></TooltipComponent>
                </div>
              </div>
              <div className="card-amount">
                <span className="amount">{count && count}</span>
              </div>
            </PreviewAltCard>
          </Col>
          <Col md="4" className="mb-5">
            <PreviewAltCard className="card-full this-card-sucks">
              <div className="card-title-group align-start mb-0">
                <div className="card-title">
                  <h6 className="subtitle">Active Team</h6>
                </div>
                <div className="card-tools">
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id="invest-withdraw"
                    text="Active Team"
                  ></TooltipComponent>
                </div>
              </div>
              <div className="card-amount">
                <span className="amount">{activecount && activecount}</span>
              </div>
            </PreviewAltCard>
          </Col>
          <Col md="4" className="mb-5">
            <PreviewAltCard className="card-full this-card-sucks">
              <div className="card-title-group align-start mb-0">
                <div className="card-title">
                  <h6 className="subtitle">Inactive Team</h6>
                </div>
                <div className="card-tools">
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id="invest-balance"
                    text="Inactive Team"
                  ></TooltipComponent>
                </div>
              </div>
              <div className="card-amount">
                <span className="amount">{inactive && inactive}</span>
              </div>
            </PreviewAltCard>
          </Col>
        </Row>
        <Usertable props={{ getCount, getActiveCount, getInactiveCount }} />
      </Content>
      <Block></Block>
    </React.Fragment>
  );
};

export default Directref;

/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
  Row,
  Col,
  BlockDes,
} from "../../../components/Component";
import P2pTransfer from "./P2pTransfer";
import P2pDepositToDeposit from "./P2pDepositToDeposit";
const TransferMain = () => {
  const [tab, setTab] = useState("1");

  return (
    <>
      <Head title="Transfer" />
      <Content>
        <BlockHead size="sm" className="mt-5">
          <BlockBetween>
            <BlockHeadContent className="mb-0 pb-0">
              <BlockTitle page>P2P TRANSFER</BlockTitle>
              <BlockDes>transfer fund peer to peer</BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs">
          <li className="nav-item" onClick={() => setTab("1")}>
            <a
              href="#overview"
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className={`nav-link${tab === "1" ? " active" : ""}`}
            >
              Income To Other Deposit Transfer
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab("2")}>
            <a
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className={`nav-link${tab === "2" ? " active" : ""}`}
              href="#year"
            >
              Deposit To Other Deposit Transfer
            </a>
          </li>
        </ul>

        <div className="tab-content mt-0">
          {tab === "1" && (
            <div className={`tab-pane${tab === "1" && " active"}`}>
              <P2pTransfer />
            </div>
          )}

          {tab === "2" && (
            <div className={`tab-pane${tab === "2" && " active"}`}>
              <P2pDepositToDeposit />
            </div>
          )}
        </div>
      </Content>
    </>
  );
};

export default TransferMain;

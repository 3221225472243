/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { PreviewAltCard, RSelect } from "../../../components/Component";
import { bulkActionOptions, isoToHumanRedable } from "../../../utils/Utils";
import { Icon } from "../../../components/Component";
import axios from "axios";

const LevelIncomeDetails = () => {
  const [data, setData] = useState([]);
  const [tablesm, updateTableSm] = useState(false);
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const onActionText = (e) => {
    console.log(e.value);
    setActionText(e.value);
  };

  const onActionClick = async () => {
    try {
      console.log("called");
      let resp = await axios.post("https://purnaagents.com/api/getLevelIncome", {
        custId: localStorage.getItem("custId"),
        LvlNo: actionText,
      });
      console.log(resp["data"]["data"]);
      setData(resp["data"]["data"]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ marginBottom: "25px" }}>
        <div className="card-inner position-relative card-tools-toggle">
          <div className="card-title-group">
            <div className="card-tools">
              <div className="form-inline flex-nowrap gx-3">
                <div className="form-wrap">
                  <RSelect
                    options={bulkActionOptions}
                    className="w-130px"
                    placeholder="Level"
                    onChange={(e) => onActionText(e)}
                  />
                </div>
                <div className="btn-wrap">
                  {loading ? (
                    <span className="d-none d-md-block">
                      <Button
                        color="light"
                        outline
                        disabled={actionText === "" ? true : false}
                        className="btn-dim"
                      >
                        <Spinner size="sm" color="light" /> please wait
                      </Button>
                    </span>
                  ) : (
                    <span className="d-none d-md-block">
                      <Button
                        color="light"
                        outline
                        disabled={actionText === "" ? true : false}
                        className="btn-dim"
                        onClick={() => onActionClick()}
                      >
                        Apply
                      </Button>
                    </span>
                  )}

                  <span className="d-md-none">
                    <Button
                      color="light"
                      outline
                      disabled={actionText === "" ? true : false}
                      className="btn-dim btn-icon"
                      onClick={() => onActionClick()}
                    >
                      <Icon name="arrow-right"></Icon>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {data && data.length > 0 ? (
          <table className="table table-responsive-sm table-bordered ">
            <thead>
              <tr>
                <th scope="col">Level</th>
                <th scope="col">Tr Id</th>
                <th scope="col">From Id</th>
                <th scope="col">Amount</th>
                <th scope="col">Description</th>
                <th scope="col">Time</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((e, i) => (
                <tr key={i}>
                  <th scope="row">{e["levelno"]}</th>
                  <th scope="row">{e["transactionid"]}</th>
                  <td>{e["childid"]}</td>
                  <td>{e["amount"]}</td>
                  <td>{e["description1"]}</td>
                  <td>{e["date1"] && isoToHumanRedable(e["date1"])}</td>
                  <td>{e["trasferstatus"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </>
  );
};

export default LevelIncomeDetails;

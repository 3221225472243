
import React from "react";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, BlockDes, Block } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import LevelIncomeTable from "./tablesused/LevelIncomeTable";

const LevelIncome = ({ ...props }) => {

    return (
        <React.Fragment>
            <Head title="My Referrals" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Level Income</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>my level income details</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <LevelIncomeTable />
            </Content>
            <Block>
            </Block>
        </React.Fragment>
    );
};

export default LevelIncome;

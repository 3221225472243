import axios from "axios";
import React, { useEffect, useState } from "react";
import { isoToHumanRedable } from "../../../../utils/Utils";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const SupportRecords = () => {
  const [data, setData] = useState([]);
  const [modalData, setModaldata] = useState("");
  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => setModalZoom(!modalZoom);

  const handleClick = async (e) => {
    // console.log(e)
    try {
      let resp = await axios({
        method: "get",
        url: `https://purnaagents.com/api/support/getSupportTicket/${e}`,
        withCredentials: true,
      });
      if (resp) {
        setModalZoom(true);
      }

      //   console.log(resp["data"]["data"]);
      setModaldata(resp["data"]["data"]);
    } catch (error) {
      // console.log(error)
      let { message } = error["response"]["data"];
      toast.info(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await toast.promise(
          axios({
            method: "get",
            url: "https://purnaagents.com/api/support/getSupportTicketByUserId",
            withCredentials: true,
          }),
          {
            pending: "Searching for the open tickets",
            success: "Data fetched successfully",
          },
          {
            autoClose: 2000,
          }
        );
        // console.log(resp)
        setData(resp.data.data);
      } catch (error) {
        let { message } = error["response"]["data"];
        toast.error(message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    })();
  }, []);

  return (
    <>
      <ToastContainer />
      <table
        className="table-responsive-sm table-bordered"
        style={{ marginTop: "25px" }}
      >
        <thead>
          <tr>
            <th>Ticket Id</th>
            <th>Your Query</th>
            <th>Date & Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((e, i) => (
              <tr key={i}>
                <td
                  onClick={() => handleClick(e._id)}
                  style={{
                    fontWeight: "bold",
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  {e["ticketId"]}
                </td>
                <td>{e["description"]}</td>
                <td>{e["createdAt"] && isoToHumanRedable(e["createdAt"])}</td>

                <td
                  style={{
                    color: `${e["status"]}` === "Close" ? "green" : "red",
                  }}
                >
                  {e["status"]}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalZoom}
        toggle={toggleZoom}
        modalClassName="zoom"
        scrollable="true"
      >
        <ModalHeader
          toggle={toggleZoom}
          close={
            <button className="close" onClick={toggleZoom}>
              <Icon name="cross" />
            </button>
          }
        >
          Responses on your queries
        </ModalHeader>
        <ModalBody>
          <ul>
            {/* {modalData &&
              modalData.map((e, i) => ( */}
            <li>
              <Card className="card-bordered" inverse color="primary">
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Reply From Admin</CardTitle>
                  <span style={{ fontWeight: "bolder" }}>
                    {" "}
                    Date <br />
                    {modalData["createdAt"] &&
                      isoToHumanRedable(modalData["createdAt"])}
                  </span>
                  <br />
                  <CardText>{modalData["description"]}</CardText>
                </CardBody>
              </Card>
            </li>
            {/* ))} */}
          </ul>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SupportRecords;

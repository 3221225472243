const menu = [

  { heading: "Dashboard" },

  {
    icon: "home-alt",
    text: "Homepage",
    link: "/invest",
  },

  {
    icon: "users",
    text: "Teams",
    active: false,
    subMenu: [

      {
        text: "Direct referral",
        link: "/referral",
      },
      {
        text: "My Team",
        link: "/level-team",
      }

    ],
  },

  {
    icon: "file-docs",
    text: "Income",
    active: false,
    subMenu: [

      // {
      //   text: "Direct Income",
      //   link: "/directIncome",
      // },

      {
        text: "Level Income",
        link: "/levelIncome",
      },

      {
        text: "ROI Income",
        link: "/roiIncome",
      },

      // {
      //   text: "Jackpot",
      //   link: "/",
      // },

      // {
      //   text: "Award & Reward",
      //   link: "/",
      // }

    ],
  },

  {
    icon: "tranx",
    text: "Wallets",
    active: false,
    link: "/wallet",

  }
  ,

  {
    icon: "help",
    text: "Support",
    active: false,
    link: "/support",

  },
  // {
  //   icon: "coins",
  //   text: "Deposit Fund",
  //   active: false,
  //   link: "/depositBankencrypt",

  // },
  // {
  //   icon: "coins",
  //   text: "P2P Transfer",
  //   active: false,
  //   link: "/p2pTransfer",

  // }

]
export default menu;

import React, { useEffect, useState } from 'react'
import Head from '../../../layout/head/Head'
import { BlockHead, BlockBetween, BlockHeadContent, BlockTitle, BlockDes } from '../../../components/Component'
import Content from '../../../layout/content/Content'
import { useLocation } from 'react-router'
import { isoToHumanRedable } from '../../../utils/Utils'
import { PaginationComponent, DataTable } from '../../../components/Component'


const DirectRefAll = () => {

    let location = useLocation()
    let [data, setData] = useState([])
    let data2 = location['state']
    console.log(location['state'])

    useEffect(() => {

        let data1 = []

        data2.forEach((e, i) => {
            e['srNo'] = i + 1
            data1.push(e)
        })

        setData(data1)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log(data)

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Head title='invest list' />

            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Direct Referral's</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>List of all referral's</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <DataTable className="card-stretch this-card-sucks-1">
                    <table className="table table-hover table-bordered  table-responsive-sm">
                        <thead>
                            <tr>
                                <th scope="col">#srno</th>
                                <th scope="col">Member ID</th>
                                <th scope="col">Username</th>
                                <th scope="col">Active Date</th>
                                <th scope="col">Joining Date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems &&
                                currentItems.map((e, i) => (
                                    <tr key={i}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{e.Cust_UserName}</td>
                                        <td>{e.Cust_Name}</td>
                                        <td>{e.Paid_Date && isoToHumanRedable(e.Paid_Date)}</td>
                                        <td>{e.Entry_Date && isoToHumanRedable(e.Entry_Date)}</td>
                                        <td style={{ color: e.Status === 'Deactive' ? 'red' : 'green' }}>{e.Status}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="card-inner">
                        {currentItems.length > 0 ? (
                            <PaginationComponent
                                itemPerPage={itemPerPage}
                                totalItems={data.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        ) : (
                            <div className="text-center">
                                <span className="text-silent">Fetching Data Please Wait</span>
                            </div>
                        )}
                    </div>
                </DataTable>
            </Content>
        </>
    )
}

export default DirectRefAll
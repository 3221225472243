/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import { Card, Form, FormGroup, Input, Label } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { useForm } from "react-hook-form";

import { Modal, ModalBody, Spinner } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const UserProfileSettingPage = ({ sm, updateSm }) => {
  const [isopen, setIsopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsopen(!isopen);

  const { errors, register, handleSubmit } = useForm();

  const onFormSubmit = async (e) => {
    try {
      setLoading(true);
      let resp = await axios.post(
        "http://localhost:4003/sspsToken/changePassword",
        {
          CusID: localStorage.getItem("custId"),
          OldPassword: e.old,
          NewPassword: e.new,
        }
      );

      setLoading(false);
      toast.success(resp.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      setLoading(false);
      let { message } = error.response.data;
      toast.error(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Head title="User List - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            {/* <div className="card-inner">
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>Save my Activity Logs</h6>
                  <p>You can save your all activity logs including unusual activity detected.</p>
                </div>
                <div className="nk-block-actions">
                  <ul className="align-center gx-3">
                    <li className="order-md-last">
                      <div className="custom-control custom-switch mr-n2">
                        <InputSwitch checked id="activity-log" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change Password</h6>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button color="primary" onClick={toggle}>
                        Change Password
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="card-body">
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>
                    2 Factor Auth &nbsp; <span className="badge badge-success ml-0">Enabled</span>
                  </h6>
                  <p>
                    Secure your account with 2FA security. When it is activated you will need to enter not only your
                    password, but also a special code using app. You will receive this code via mobile application.{" "}
                  </p>
                </div>
                <div className="nk-block-actions">
                  <Button color="primary">Disable</Button>
                </div>
              </div>
            </div> */}
          </div>

          <Modal isOpen={isopen} toggle={toggle}>
            <ModalBody>
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label"> Enter Old Password </Label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="default-01"
                      name="old"
                      ref={register({ required: "This field is required" })}
                      placeholder="Enter Old Password"
                      className="form-control-lg form-control"
                    />
                    {errors.old && (
                      <span className="invalid">{errors.old.message}</span>
                    )}
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label"> Enter New Password </Label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="default-01"
                      name="new"
                      ref={register({ required: "This field is required" })}
                      placeholder="Enter New Password"
                      className="form-control-lg form-control"
                    />
                    {errors.new && (
                      <span className="invalid">{errors.new.message}</span>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    size="lg"
                    className="btn-block"
                    type="submit"
                    color="primary"
                  >
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "change password"
                    )}
                  </Button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;


import React from "react";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, BlockDes, Block } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import DailyStakingHistoryTable from "./tablesused/DailyStakingHistoryTable";


const DailyStaking = ({ ...props }) => {

    return (
        <React.Fragment>
            <Head title="My Referrals" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Daily Staking</BlockTitle>
                            <BlockDes className="text-soft">
                                <p> Daily Staking Income Details </p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <DailyStakingHistoryTable />
            </Content>
            <Block>
            </Block>
        </React.Fragment>
    );
};

export default DailyStaking;

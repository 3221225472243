/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Tree from "react-d3-tree";

const MyTreeComponent = () => {
  const [treeData, setTreeData] = useState({
    name: "Root",
    children: [
      {
        name: "Node 1",
        children: [{ name: "Node 1.1" }, { name: "Node 1.2" }],
      },
      {
        name: "Node 2",
        children: [{ name: "Node 2.1" }, { name: "Node 2.2" }],
      },
    ],
  });

  const renderCustomNodeElement = ({ nodeDatum }) => {
    return (
      <g>
        <circle r={10} fill="lightblue" />
        <text dx={-15} dy={5} fontSize={10}>
          {nodeDatum.name} {/* Display user information here */}
        </text>
      </g>
    );
  };

  const handleNodeClick = (nodeData) => {
    // Handle node click if needed
    console.log("Clicked node:", nodeData);
  };

  const fetchTreeData = async (sponsorId) => {
    try {
      // Replace with your data fetching logic
      const response = await fetch(
        `http://localhost:3001/getTreeData/${sponsorId}`
      );
      const data = await response.json();
      setTreeData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "80%", height: "80vh" }}>
        {treeData ? (
          <Tree
            data={treeData}
            translate={{ x: 50, y: 50 }} // Adjust starting position
            orientation="vertical" // Set tree orientation to vertical
            onClick={handleNodeClick}
            nodeSvgShape={{
              shape: "circle",
              shapeProps: {
                r: 10,
              },
            }}
          />
        ) : (
          <button onClick={() => fetchTreeData("yourSponsorId")}>
            Fetch Tree Data
          </button>
        )}
      </div>
    </div>
  );
};

export default MyTreeComponent;

import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { PaginationComponent, DataTable } from "../../../components/Component";
import { Row, Col } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import {
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
} from "../../../components/Component";
import axios from "axios";
import { useLocation } from "react-router";
import { isoToHumanRedable } from "../../../utils/Utils";

const TransactionHistory = () => {
  let lc = useLocation();
  let { marketId } = lc.state;

  const [data, setData] = useState([]);
  const [onSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(50);
  const [marketname, setMarketname] = useState("");
  const [balance, setBalance] = useState(0);
  const [debitbalance, setDebitbalance] = useState(0);
  const [cerditbalance, setCreditbalance] = useState(0);

  // Sorting data

  useEffect(() => {
    (async () => {
      let res = await axios({
        method: "post",
        url: "https://purnaagents.com/api/transactionInfo",
        data: {
          custId: localStorage.getItem("custId"),
          marketId: marketId,
        },
      });

      console.log(res);

      setMarketname(res.data.data.marketName);
      setDebitbalance(res.data.data.debit);
      setCreditbalance(res.data.data.credit);
      setBalance(res.data.data.balance);
    })();
  });

  useEffect(() => {
    (async () => {
      let resp = await axios.post(
        "https://purnaagents.com/api/transactionReport",
        { custId: localStorage.getItem("custId"), marketId: marketId }
      );
      setData(resp["data"]["data"]);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to set the action to be taken in table header

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        return item.Cust_Name.toLowerCase().includes(
          onSearchText.toLowerCase()
        );
        // item.email.toLowerCase().includes(onSearchText.toLowerCase())
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchText, setData]);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  console.log(currentItems);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Transaction History" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                Transaction History of {marketname && marketname}
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row className="g-gs">
          <Col sm="4">
            <div className="nk-order-ovwg-data sell">
              <div className="amount">
                {cerditbalance && cerditbalance}
                <small className="currenct currency-usd"> Credit</small>
              </div>
            </div>
          </Col>
          <Col sm="4">
            <div className="nk-order-ovwg-data sell">
              <div className="amount">
                {debitbalance && debitbalance}{" "}
                <small className="currenct currency-usd">Debit</small>
              </div>
            </div>
          </Col>
          <Col sm="4 mb-5">
            <div className="nk-order-ovwg-data sell">
              <div className="amount">
                {balance && balance}{" "}
                <small className="currenct currency-usd">Balance</small>
              </div>
            </div>
          </Col>
        </Row>
        <DataTable className="card-stretch">
          <table className="table table-hover table-bordered table-responsive-sm">
            <thead>
              <tr>
                <th scope="col">#TrID</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Credit</th>
                <th scope="col">Debit</th>
                <th scope="col">Description</th>
                <th scope="col">Wallet Type</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((e, i) => (
                <tr key={i}>
                  <th scope="row">{e.TrID}</th>
                  <td>{e.dot && isoToHumanRedable(e.dot)}</td>
                  <td>{e.credit}</td>
                  <td>{e.Debit}</td>
                  <td>{e.description}</td>
                  <td>{e.marketName}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="card-inner">
            {currentItems.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Fetching Data Please Wait</span>
              </div>
            )}
          </div>
        </DataTable>
      </Content>
    </React.Fragment>
  );
};
export default TransactionHistory;

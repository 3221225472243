import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Icon,
  PaginationComponent,
  Button,
  DataTable,
  RSelect,
} from "../../../components/Component";
import { Spinner } from "reactstrap";
import { bulkActionOptions, isoToHumanRedable } from "../../../utils/Utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const LevelUserTable = ({ props }) => {
  const [data, setData] = useState([]);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  // Sorting data

  const onActionClick = async () => {
    try {
      setLoading(true);
      const resp = await toast.promise(
        axios.post("https://purnaagents.com/api/directTeam", {
          CusID: localStorage.getItem("custId"),
          lvl: actionText,
        }),
        {
          pending: "Fetching Level Data",
          success: "Level Data Fetched Successfully",
        },
        {
          autoClose: 2000,
          theme: "dark",
        }
      );
      console.log(resp);
      setLoading(false);
      setData(resp["data"]["data"]);
    } catch (error) {
      setLoading(false);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await toast.promise(
          axios.post("https://purnaagents.com/api/directTeam", {
            CusID: localStorage.getItem("custId"),
            lvl: 1,
          }),
          {
            pending: "Fetching Team Records",
            success: "Records Fetched Successfully",
          },
          {
            autoClose: 2000,
          }
        );

        setData(resp["data"]["data"]);
        props(resp.data.data.length);
      } catch (error) {
        let { message } = error["response"]["data"];
        toast.error(message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData]);

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        return item.Cust_Name.toLowerCase().includes(
          onSearchText.toLowerCase()
        );
        // item.email.toLowerCase().includes(onSearchText.toLowerCase())
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Head title="User List"></Head>
      <ToastContainer />
      <DataTable className="card-stretch">
        <div className="card-inner position-relative card-tools-toggle">
          <div className="card-title-group">
            <div className="card-tools">
              <div className="form-inline flex-nowrap gx-3">
                <div className="form-wrap">
                  <RSelect
                    options={bulkActionOptions}
                    className="w-130px"
                    placeholder="Level"
                    onChange={(e) => onActionText(e)}
                  />
                </div>
                <div className="btn-wrap">
                  {loading ? (
                    <span className="d-none d-md-block">
                      <Button
                        color="light"
                        outline
                        disabled={actionText === "" ? true : false}
                        className="btn-dim"
                      >
                        <Spinner size="sm" color="light" /> please wait
                      </Button>
                    </span>
                  ) : (
                    <span className="d-none d-md-block">
                      <Button
                        color="light"
                        outline
                        disabled={actionText === "" ? true : false}
                        className="btn-dim"
                        onClick={() => onActionClick()}
                      >
                        Apply
                      </Button>
                    </span>
                  )}

                  <span className="d-md-none">
                    <Button
                      color="light"
                      outline
                      disabled={actionText === "" ? true : false}
                      className="btn-dim btn-icon"
                      onClick={() => onActionClick()}
                    >
                      <Icon name="arrow-right"></Icon>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
            <div className="card-tools mr-n1">
              <ul className="btn-toolbar gx-1">
                <li>
                  <Button
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle();
                    }}
                    className="btn-icon search-toggle toggle-search"
                  >
                    <Icon name="search"></Icon>
                  </Button>
                </li>
                <li className="btn-toolbar-sep"></li>
                <li>
                  <div className="toggle-wrap">
                    <Button
                      className={`btn-icon btn-trigger toggle ${
                        tablesm ? "active" : ""
                      }`}
                      onClick={() => updateTableSm(true)}
                    >
                      <Icon name="menu-right"></Icon>
                    </Button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={`card-search search-wrap ${!onSearch && "active"}`}>
            <div className="card-body">
              <div className="search-content">
                <Button
                  onClick={() => {
                    setSearchText("");
                    toggle();
                  }}
                  className="search-back btn-icon toggle-search"
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder="Search by user or email"
                  value={onSearchText}
                  onChange={(e) => onFilterChange(e)}
                />
                <Button className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-hover table-bordered table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">#srno</th>
              <th scope="col">Member ID</th>
              <th scope="col">Level</th>
              <th scope="col">Username</th>
              <th scope="col">Joining Date</th>
              <th scope="col">Purchase Amt</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{e.Cust_UserName}</td>
                <td>{e.lvlno}</td>
                <td>{e.Cust_Name}</td>
                <td>{e.Entry_Date && isoToHumanRedable(e.Entry_Date)}</td>
                <td>{e.pdc}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="card-inner">
          {currentItems.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">
                {data && data.length} records found{" "}
              </span>
            </div>
          )}
        </div>
      </DataTable>
    </React.Fragment>
  );
};
export default LevelUserTable;

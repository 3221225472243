import React, { useEffect, useState } from 'react'
import Head from '../../../layout/head/Head'
import { BlockHead, BlockBetween, BlockHeadContent, BlockTitle, BlockDes } from '../../../components/Component'
import Content from '../../../layout/content/Content'
import { useLocation } from 'react-router'
import { isoToHumanRedable, toastError } from '../../../utils/Utils'
import { PaginationComponent, DataTable } from '../../../components/Component'
import { ToastContainer } from 'react-toastify'
import { Icon } from '../../../components/Component'
import axios from 'axios'


const InvestList = () => {

    let location = useLocation()
    let [data, setData] = useState([])
    let [petdata, setPetdata] = useState([])
    const [isopen, setIsopen] = useState(false)

    const toggleOpen = async (e) => {

        try {
            let resp = await axios.post('https://purnaagents.com/api/getInvestmentProfit', { custId: localStorage.getItem('custId'), linkTable: e })
            setPetdata(resp['data']['data'])
            setIsopen(true)

        } catch (error) {
            let { message } = error['response']['data']
            toastError(message)
        }

    }

    const toggleClose = async (e) => {
        setIsopen(false)
    }

    let data2 = location['state']

    useEffect(() => {

        let data1 = []

        data2.forEach((e, i) => {
            e['srNo'] = i + 1
            data1.push(e)
        })

        setData(data1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log(data)

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Head title='invest list' />
            <ToastContainer />

            <Content className='mt-3'>
                <BlockHead size="sm" className='mt-5'>
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Purchase History</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>List of all purchase records</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <DataTable className="card-stretch this-card-sucks-1">
                    <table className="table table-hover table-bordered  table-responsive-sm">
                        <thead>
                            <tr>
                                <th scope="col">#Sr no</th>
                                <th scope="col">Ref Number</th>
                                <th scope="col">Timestamp</th>
                                <th scope="col">Value</th>
                                <th scope="col">Maximum Benefit</th>
                                <th scope="col">Payment Released</th>
                                <th scope="col">view</th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentItems &&
                                currentItems.map((e, i) => (
                                    <>
                                        <tr key={i}>
                                            <th scope="row">{e['srNo']}</th>
                                            <td >{e.refno}</td>
                                            <td>{e.purdate && isoToHumanRedable(e.purdate)}</td>
                                            <td>{e.puramt}</td>
                                            <td>{e.maxwithdraw}</td>
                                            <td>{e.payment_relased}</td>

                                            {
                                                isopen
                                                    ?
                                                    <td
                                                        style={{ color: 'white', display: 'hide' }}
                                                        onClick={() => toggleClose(e['refno'])}
                                                    >
                                                        <span style={{ color: 'black' }}>
                                                            <Icon name='chevron-up' style={{
                                                                fontSize: "30px",
                                                                cursor: "pointer"
                                                            }} />
                                                        </span>



                                                    </td>
                                                    :
                                                    <td
                                                        style={{ color: 'white', display: 'hide' }}
                                                        onClick={() => toggleOpen(e['refno'])}
                                                    >
                                                        <span style={{ color: 'black' }}>
                                                            <Icon name='chevron-down' style={{
                                                                fontSize: "30px",
                                                                cursor: "pointer"
                                                            }} />
                                                        </span>



                                                    </td>

                                            }

                                        </tr>

                                        {
                                            isopen &&
                                            <tr>
                                                <td colSpan='7'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Ref Number</th>
                                                                <th scope='col'> Tx id </th>
                                                                <th scope='col'> Credit </th>
                                                                <th scope='col'> Debit </th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Date</th>
                                                                <th scope="col">ROI Rate</th>
                                                                <th scope="col">Credit Status</th>
                                                                <th scope="col">Credit Date</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {petdata && petdata.map((x, y) => (

                                                                <tr>
                                                                    <th scope="row">{x.linktable}</th>
                                                                    <td>{x['txno']}</td>
                                                                    <td>{x['credit']}</td>
                                                                    <td>{x['debit']}</td>
                                                                    <td>{x['description']}</td>
                                                                    <td>{x['dot'] && isoToHumanRedable(x['dot'])}</td>
                                                                    <td>{x['rate']}</td>
                                                                    <td>{x['IsCredit'] > 0 ? 'Success' : 'Pending'}</td>
                                                                    <td>{x['IsCreditDate'] && isoToHumanRedable(x['IsCreditDate'])}</td>
                                                                </tr>

                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        }

                                    </>

                                ))}
                        </tbody>
                    </table>
                    <div className="card-inner">
                        {currentItems.length > 0 ? (
                            <PaginationComponent
                                itemPerPage={itemPerPage}
                                totalItems={data.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        ) : (
                            <div className="text-center">
                                <span className="text-silent">Fetching Data Please Wait</span>
                            </div>
                        )}
                    </div>
                </DataTable>
            </Content>
        </>
    )
}

export default InvestList










/* eslint-disable no-unused-vars */
import Axios from "axios";
import { toast } from "react-toastify";

const handleSignout = async () => {
  const API_BASE = "https://purnaagents.com/api";

  try {
    // let accesToken = localStorage.getItem("token");
    localStorage.clear();
    const res = await Axios.get(API_BASE + `/logout`, {
      withCredentials: true,
    });
    if (res) {
      setTimeout(() => {
        toast.success(res.data.message);
        window.location.replace("/auth-login");
      }, 1600);
    }
  } catch (error) {
    console.log(error);
    // setErrorMessage(error.response.data.customError);
  }
};

export default handleSignout;

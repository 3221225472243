/* eslint-disable no-unused-vars */
import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";

import Invest from "../pages/Invest";
import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
import ProjectListPage from "../pages/pre-built/projects/ProjectList";
import UserListRegularPage from "../pages/pre-built/user-manage/UserListRegular";
import UserContactCardPage from "../pages/pre-built/user-manage/UserContactCard";
import UserDetailsPage from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";
import Directref from "../pages/mypages/Directref";
import Downline from "../pages/mypages/Downline";
import WithdrawReq from "../pages/mypages/WithdrawReq";
import Activation from "../pages/mypages/topup/Activation";
import ActivationHistory from "../pages/mypages/topup/ActivationHistory";
import AddFund from "../pages/mypages/fundWallet/AddFund";
import ConvertFund from "../pages/mypages/fundWallet/ConvertFund";
import P2pTransfer from "../pages/mypages/fundWallet/P2pTransfer";
import Transferhistory from "../pages/mypages/fundWallet/Transferhistory";
import AddfundHistory from "../pages/mypages/fundWallet/AddfundHistory";
import UpgradePool from "../pages/mypages/topup/UpgradePool";
import ReferralIncome from '../pages/mypages/incomeReports/ReferralIncome'

import LevelIncome from "../pages/mypages/bankencrypt/incomecomponents/LevelIncome";
import BoostingIncome from "../pages/mypages/incomeReports/BoostingIncome";
import SkyforceIncome from "../pages/mypages/incomeReports/SkyforceIncome";
import TeamPromoIncome from "../pages/mypages/incomeReports/TeamPromoIncome";
import TransactionHistory from "../pages/mypages/Safferon/TransactionHIstory";
import LevelTeam from "../pages/mypages/Safferon/LevelTeam";
import Support from "../pages/mypages/Support";

import Geneoloy from "../pages/mypages/bankencrypt/Geneology";
import InvestList from "../pages/mypages/bankencrypt/InvestList";
import DirectRefAll from "../pages/mypages/bankencrypt/DirectRefAll";
import RoiIncome from "../pages/mypages/bankencrypt/incomecomponents/RoiIncome";
import JackpotBonus from "../pages/mypages/bankencrypt/incomecomponents/JackpotBonus";
import DailyStaking from "../pages/mypages/bankencrypt/incomecomponents/DailyStaking";
import Updatekyc from "../pages/mypages/bankencrypt/kyc/Updatekyc";
import SspsDeposite from "../pages/mypages/bankencrypt/SspsDeposite";
import TransferMain from "../pages/mypages/fundWallet/TransferMain";
import UpdateBankData from "../pages/mypages/bankencrypt/kyc/UpdateBankData";
import KycListRegular from '../pages/pre-built/kyc-list-regular/KycListRegular';
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import KycApplications from "../pages/pre-built/kyc-list-regular/KycApplications";
import KycForms from '../pages/pre-built/kyc-list-regular/KycForms';
import Wallet from "../pages/Wallet";


const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        <Route exact path={`${process.env.PUBLIC_URL}/crypto`} component={Crypto}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest`} component={Invest}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route>

        {/*Pre-built Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/project-card`} component={ProjectCardPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/project-list`} component={ProjectListPage}></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-regular`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-regular/`} component={UserProfileLayout}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
          component={UserProfileLayout}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-activity/`} component={UserProfileLayout}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-setting/`} component={UserProfileLayout}></Route>
        <Route //Context api added
          exact
          path={`${process.env.PUBLIC_URL}/user-contact-card`}
          render={() => (
            <UserContextProvider>
              <UserContactCardPage />
            </UserContextProvider>
          )}
        ></Route>

        {/*Demo Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>

        {/*Components*/}

        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Invest}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/referral`} component={Directref}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/wallet`} component={Wallet}></Route>

        {/* used In SkyHigh Win */}
        <Route exact path={`${process.env.PUBLIC_URL}/directRef`} component={Downline}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/withdrawReq`} component={WithdrawReq}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/topupActivation`} component={Activation}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/activationHistory`} component={ActivationHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/addFundSkyhigh`} component={AddFund}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/convertFundSkyhigh`} component={ConvertFund}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/p2pTransfer`} component={TransferMain}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/transferHistory`} component={Transferhistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/addFundHistory`} component={AddfundHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/topupUpgrade`} component={UpgradePool}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/referralIncome`} component={ReferralIncome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/levelIncome`} component={LevelIncome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/boostingIncome`} component={BoostingIncome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/skyforceBoosting`} component={SkyforceIncome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/teamPromoBonus`} component={TeamPromoIncome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/transactionHistory`} component={TransactionHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/support`} component={Support}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/level-team`} component={LevelTeam}></Route>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/depositBankencrypt`} component={Deposite}></Route> */}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/depositBankencrypt`} component={SspsDeposite}></Route> */}

        <Route exact path={`${process.env.PUBLIC_URL}/treeView`} component={Geneoloy}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/investmentList`} component={InvestList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/directRefList`} component={DirectRefAll}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/levelIncome`} component={LevelIncome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/roiIncome`} component={RoiIncome}></Route>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/directIncome`} component={DirectIncome}></Route> */}
        <Route exact path={`${process.env.PUBLIC_URL}/jackpotBonusTable`} component={JackpotBonus}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/dailyStakingHistory`} component={DailyStaking}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/updateKyc`} component={Updatekyc}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/bank-details`} component={UpdateBankData}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-application`} component={KycApplications }></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-form`} component={KycForms }></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-list-regular`} component={KycListRegular}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-details-regular/:kycId`} component={KycDetailsRegular}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;

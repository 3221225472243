/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  BlockDes,
  PreviewAltCard,
  RSelect,
} from "../../../../components/Component";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Button, Row, Col, Spinner, Card } from "reactstrap";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { toastSuccess } from "../../../../utils/Utils";
import DatePicker from "react-datepicker";
import { useRecoilState } from "recoil";
import { userState } from "../../../../atom";

const resizeImage = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800, // New width (adjust as needed)
      800, // New height (adjust as needed)
      "JPEG", // Output file format
      80, // Quality (adjust as needed)
      0, // Rotation
      (uri) => {
        resolve(uri);
      },
      "file", // Output type ('base64', 'blob', or 'file')
      800, // Max file size in bytes (optional)
      800, // Max width (optional)
      800 // Max height (optional)
    );
  });
};

const Updatekyc = () => {
  const { errors, register, handleSubmit, reset } = useForm();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState();
  const [kycData, setKycData] = useState();
  const [dob, setDOB] = useState(new Date());
  const [userData, setUserData] = useRecoilState(userState);

  const API_Base_Url = "https://purnaagents.com/api";
  //   console.log(dob)

  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          method: "get",
          url: API_Base_Url + "/kyc/kycData",
          withCredentials: true,
        });

        // console.log(response);
        setKycData(response.data.data);
        // setStatus(isUpdated["data"]["kycStatus"]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onFormSubmit = async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("aadharFront", await resizeImage(e.adharFront[0]));
      formData.append("aadharBack", await resizeImage(e["adharBack"][0]));
      formData.append("photo", await resizeImage(e["photo"][0]));
      formData.append("pancard", await resizeImage(e["pancard"][0]));
      formData.append("aadharCardNumber", e["aadharcardNumber"]);
      formData.append("accHolderName", e["accHolderName"]);
      formData.append("panNumber", e["panNumber"]);
      formData.append("gender", gender);
      formData.append("dob", dob);
      formData.append("address", e["address"]);
      console.log(formData);

      let resp = await axios({
        method: "post",
        url: API_Base_Url + "/kyc/update",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });
      reset();
      let { message } = resp["data"];
      console.log(resp);
      toastSuccess(message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const genderList = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  return (
    <>
      <Head title="update kyc" />
      <ToastContainer />
      <Content>
        <BlockHead size="sm" className="mt-5">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h5">Update KYC</BlockTitle>
              <BlockDes className="text-soft">
                <p> Please Complete The Below Form </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Row>
          <Col lg="2"></Col>
          <Col lg="8">
            <PreviewAltCard>
              {status === 3 ? (
                <Card>
                  <div
                    style={{
                      marginBottom: "10px",
                      fontWeight: "bolder",
                      padding: "15px",
                      color: "Blue",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {" "}
                    Your KYC Verification is Pending ...!
                  </div>
                </Card>
              ) : status === 1 ? (
                <Card>
                  <div
                    style={{
                      marginBottom: "10px",
                      fontWeight: "bolder",
                      padding: "15px",
                      color: "green",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {" "}
                    Your KYC is Verified ...!
                  </div>
                </Card>
              ) : status === 2 ? (
                <Card>
                  <div
                    style={{
                      marginBottom: "10px",
                      fontWeight: "bolder",
                      padding: "15px",
                      color: "red",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {" "}
                    Your KYC is Rejected ...!
                  </div>
                </Card>
              ) : status === 0 ? (
                <Card>
                  <div
                    style={{
                      marginBottom: "10px",
                      fontWeight: "bolder",
                      padding: "15px",
                      color: "black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {" "}
                    Please Update Your KYC...!
                  </div>
                </Card>
              ) : null}

              {status && status === 1 ? null : (
                <Form
                  className="is-alter"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Name
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        name="accHolderName"
                        ref={register({ required: "This field is required" })}
                        placeholder="Please Enter Your Name"
                        className="form-control-lg form-control"
                      />
                      {errors.accHolderName && (
                        <span className="invalid">
                          {errors.accHolderName.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        gender
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <RSelect
                        name="gender"
                        selected={gender}
                        options={genderList}
                        onChange={(e) => setGender(e.value)}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label" htmlFor="birth-day">
                      Date of Birth
                    </label>
                    <DatePicker
                      type="date"
                      className="form-control"
                      name="dob"
                      ref={register({ required: "This field is required" })}
                      selected={dob}
                      onChange={(date) => setDOB(date)}
                      maxDate={new Date()}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Address
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        name="address"
                        ref={register({ required: "This field is required" })}
                        placeholder="Please Enter Your address"
                        className="form-control-lg form-control"
                      />
                      {errors.address && (
                        <span className="invalid">
                          {errors.address.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Photo
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="file"
                        name="photo"
                        ref={register({ required: "This field is required" })}
                        placeholder="Upload your photo"
                        className="form-control-lg form-control"
                      />
                      {errors.photo && (
                        <span className="invalid">{errors.photo.message}</span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Aadhar No
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        name="aadharcardNumber"
                        ref={register({ required: "This field is required" })}
                        placeholder="Please Enter Aadhar no."
                        className="form-control-lg form-control"
                      />
                      {errors.aadharcardNumber && (
                        <span className="invalid">
                          {errors.aadharcardNumber.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Aadhar Front Side
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="file"
                        name="adharFront"
                        ref={register({ required: "This field is required" })}
                        placeholder="Enter your customer id"
                        className="form-control-lg form-control"
                      />
                      {errors.adharFront && (
                        <span className="invalid">
                          {errors.adharFront.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Aadhar Back Side
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="file"
                        name="adharBack"
                        ref={register({ required: "This field is required" })}
                        placeholder="upload Aadhar back Side"
                        className="form-control-lg form-control"
                      />
                      {errors.adharBack && (
                        <span className="invalid">
                          {errors.adharBack.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Pan No
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        name="panNumber"
                        ref={register({ required: "This field is required" })}
                        placeholder="Please Enter Pan no."
                        className="form-control-lg form-control"
                      />
                      {errors.panNumber && (
                        <span className="invalid">
                          {errors.panNumber.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        PanCard
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="file"
                        name="pancard"
                        ref={register({ required: "This field is required" })}
                        placeholder="upload Pancard"
                        className="form-control-lg form-control"
                      />
                      {errors.pancard && (
                        <span className="invalid">
                          {errors.pancard.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    {loading ? (
                      <Button size="sm" className="btn-block" color="primary">
                        <Spinner size="sm" />
                        Please wait
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        className="btn-block"
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              )}
            </PreviewAltCard>
            {/* <img
              src={kycData && kycData.aadharFrontUrl}
              alt="S3"
              style={{ maxWidth: "5%", height: "auto" }}
            /> */}
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default Updatekyc;

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import "./mypages/bankencrypt/styles/invest.css";
import { ClipLoader } from "react-spinners";
import { isoToHumanRedable, toastError } from "../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "../pages/mypages/bankencrypt/styles/Table.css";

import {
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  Block,
} from "../components/Component";

import { useEffect } from "react";
import axios from "axios";
import InvestOverview from "./mypages/bankencrypt/InvestOverview";
import DashBoardStats from "./mypages/components/DashBoardStats";
import GlobalStats from "./mypages/components/GlobalStats";
import BuyPackageComponent from "./mypages/bankencrypt/BuyPackageComponent";

const InvestHomePage = () => {
  const [depbal, setDepBal] = useState(null);
  const [earnbal, setEarnbal] = useState(null);
  const [data, setData] = useState(null);
  const [purchasedata, setPurchasedata] = useState(null);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await toast.promise(
  //         axios({
  //           method: "post",
  //           url: "https://purnaagents.com/api/getEarnWalletBalance",
  //           data: {
  //             userId: localStorage.getItem("custId"),
  //           },
  //         }),
  //         {
  //           pending: "Fetching Earning Wallet Balance",
  //           success: "Balance Fetched Successfully 👌",
  //           error: "Unable To Fetch Balance",
  //         },
  //         {
  //           autoClose: 1000,
  //           closeOnClick: true,
  //         }
  //       );

  //       if (res["data"]) {
  //         setEarnbal(res.data["incomeWalletBal"]);
  //       }

  //       let depWal = await axios({
  //         method: "post",
  //         url: "https://purnaagents.com/api/getDepositeBalance",
  //         data: {
  //           userId: localStorage.getItem("custId"),
  //         },
  //       });

  //       if (depWal["data"]) {
  //         setDepBal(depWal.data["depositeBalance"]);
  //       }
  //     } catch (error) {
  //       let { message } = error["response"]["data"];
  //       toastError(message);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        let resp = await axios.post(
          "https://purnaagents.com/api/fetchRefTopFive",
          {
            custId: localStorage.getItem("custId"),
          }
        );
        setData(resp["data"]["data"]);
      } catch (error) {
        alert(error["message"]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData]);

  useEffect(() => {
    (async () => {
      try {
        let resp = await axios.post(
          "https://purnaagents.com/api/purchaseHistory",
          {
            CusID: localStorage.getItem("custId"),
          }
        );

        console.log(resp["data"]["data"]);
        setPurchasedata(resp["data"]["data"]);
      } catch (error) {
        let { message } = error["response"]["data"];
        toastError(message);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData]);

  const [sm, updateSm] = useState(false);
  return (
    <React.Fragment>
      <ToastContainer />
      <Head title="Homepage" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p></p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                ></div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <GlobalStats />
        </Block>

        {/* dashboard Stats Components */}
        <DashBoardStats />

        {/* <Converter /> */}

        {/* <Row className="g-gs">
          <Col md="8" className="mt-3">
            <PreviewAltCard
              className="card-full card-bordered"
              bodyClass="d-flex flex-column h-100"
            >
              <div className="card-title">
                <h6 className="title">My Income Status</h6>
              </div>
              {depbal !== null && earnbal !== null ? (
                <InvestOverview
                  props={{ deposite: depbal, earning: earnbal }}
                />
              ) : (
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ClipLoader color="#36d7b7" size={100} />
                  <span
                    style={{
                      margin: "auto",
                      fontSize: "20px",
                      marginTop: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    please wait{" "}
                  </span>
                </div>
              )}
            </PreviewAltCard>
          </Col>

          <Col sm="4" className="mt-3">
            <BuyPackageComponent />
          </Col>
        </Row> */}

        <Row className="mt-5">
          <Col lg={12}>
            <PreviewAltCard className="card-full this-card-sucks-1">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <h6> PURCHASE HISTORY </h6>

                {purchasedata && (
                  <Link
                    to={{
                      pathname: "/investmentList",
                      state: purchasedata,
                    }}
                  >
                    <span>
                      <b>View All</b>
                    </span>
                  </Link>
                )}
              </div>
              <span>
                Total records{" "}
                <b>
                  {purchasedata && purchasedata.length
                    ? purchasedata.length
                    : 0}{" "}
                </b>{" "}
              </span>
              <table className="table table-hover table-bordered table-responsive-sm">
                <thead>
                  <tr>
                    <th scope="col">#Sr no</th>
                    <th scope="col">Ref Number</th>
                    <th scope="col">Time stamp</th>
                    <th scope="col">Value</th>
                    <th scope="col">Maximum Benefit</th>
                    <th scope="col">Payment Released</th>
                  </tr>
                </thead>
                <tbody>
                  {purchasedata &&
                    purchasedata.slice(0, 10).map((e, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{e.refno}</td>
                        <td>{e.purdate && isoToHumanRedable(e.purdate)}</td>
                        <td>{e.puramt}</td>
                        <td>{e.maxwithdraw}</td>
                        <td>{e.payment_relased}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </PreviewAltCard>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={12}>
            <PreviewAltCard className="card-full this-card-sucks-1">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <h6> DIRECT REFFERAL </h6>

                {data && data.length > 0 ? (
                  <Link
                    to={{
                      pathname: "/directRefList",
                      state: data,
                    }}
                  >
                    <span>
                      <b>View All</b>
                    </span>
                  </Link>
                ) : null}
              </div>
              <span>
                Total records <b>{data && data.length} </b>{" "}
              </span>
              <table className="table table-hover table-bordered table-responsive-sm">
                <thead>
                  <tr>
                    <th scope="col">#Sr no</th>
                    <th scope="col">Member ID</th>
                    <th scope="col">Username</th>
                    <th scope="col">Active Date</th>
                    <th scope="col">Joining Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((e, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{e.Cust_UserName}</td>
                        <td>{e.Cust_Name}</td>
                        <td>{e.Paid_Date && isoToHumanRedable(e.Paid_Date)}</td>
                        <td>
                          {e.Entry_Date && isoToHumanRedable(e.Entry_Date)}
                        </td>
                        <td
                          style={{
                            color: e.Status === "Deactive" ? "red" : "green",
                          }}
                        >
                          {e.Status}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </PreviewAltCard>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default InvestHomePage;

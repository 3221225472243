/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  BlockDes,
  PreviewAltCard,
  Block,
} from "../../../../components/Component";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Button, Row, Col, Spinner, Card } from "reactstrap";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { toastSuccess } from "../../../../utils/Utils";
import customStyle from "../../../../pages/pre-built/kyc-list-regular/KycForms.module.css";
import { useRecoilState } from "recoil";
import { userBankState } from "../../../../atom";

const UpdateBankData = () => {
  const { errors, register, handleSubmit, reset } = useForm();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useRecoilState(userBankState);

  const API_Base_Url = "https://purnaagents.com/api";

  useEffect(() => {
    (async () => {
      let resp = await axios({
        method: "get",
        url: API_Base_Url + "/kyc/bankDetails",
        withCredentials: true,
      });

      // console.log(resp);
      setBankData(resp["data"]["data"]);
    })();
  }, []);

  const onFormSubmit = async (e) => {
    try {
      setLoading(true);
      // const formData = new FormData();
      // console.log(formData);

      let resp = await axios({
        method: "post",
        url: API_Base_Url + "/kyc/update/bank-data",
        data: e,
        withCredentials: true,
      });
      reset();
      let { message } = resp["data"];
      toastSuccess(message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Head title="update kyc" />
      <ToastContainer />
      <Content size="sm">
        <div className="kyc-app wide-sm m-auto">
          <BlockHead size="sm" className="wide-xs mx-auto mt-5">
            <BlockHeadContent className="text-center">
              <BlockTitle tag="h4" className="fw-normal ">
                Bank Data
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  To comply with regulations each customer will have to go
                  through bank details to prevent fraud causes.
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          {!bankData ? (
            <Block>
              <Card className="card-bordered">
                <div className="nk-kycfm">
                  <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className={customStyle.nk_kycfm_head}>
                      <div className={customStyle.nk_kycfm_count}>01</div>
                      <div className="nk-kycfm-title">
                        <h5 className="title">Bank Details</h5>
                        <p className="sub-title">
                          Your bank details required for deposit and withdrawal
                        </p>
                      </div>
                    </div>
                    <div className={customStyle.nk_kycfm_content}>
                      <div className={customStyle.nk_kycfm_note}>
                        {/* <TooltipComponent
                        icon="info-fill"
                        direction="right"
                        text="KYC forms"
                        id="kyc-forms-tooltip"
                      /> */}
                        <p>
                          Please type carefully and fill out the form with your
                          bank details. Your can’t edit these details once you
                          submitted the form.
                        </p>
                      </div>
                      <Row className="g-4">
                        <Col md="6">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                Bank Name <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                name="bankName"
                                ref={register({
                                  required: "This field is required",
                                })}
                                placeholder="Please enter bank name"
                              />
                              {errors.bankName && (
                                <span className="invalid">
                                  {errors.bankName.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                Ifsc Code <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                name="ifscCode"
                                ref={register({
                                  required: "This field is required",
                                })}
                                placeholder="Please enter bank ifsc code "
                              />
                              {errors.ifscCode && (
                                <span className="invalid">
                                  {errors.ifscCode.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                Bank A/C Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                name="accountNumber"
                                ref={register({
                                  required: "This field is required",
                                })}
                                placeholder="Please enter your account no."
                              />
                              {errors.accountNumber && (
                                <span className="invalid">
                                  {errors.accountNumber.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                A/C Holder Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                name="accountName"
                                ref={register({
                                  required: "This field is required",
                                })}
                                placeholder="Please enter your account no."
                              />
                              {errors.accountName && (
                                <span className="invalid">
                                  {errors.accountName.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                Branch Name and Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                name="branch"
                                ref={register({
                                  required: "This field is required",
                                })}
                                placeholder="Please enter branch name and address"
                              />
                              {errors.branch && (
                                <span className="invalid">
                                  {errors.branch.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className={customStyle.nk_kycfm_footer}>
                      <div className="form-group">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="tc-agree"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="tc-agree"
                          >
                            I Have Read The{" "}
                            <a
                              href="#terms"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              Terms Of Condition
                            </a>{" "}
                            And{" "}
                            <a
                              href="#policy"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="info-assure"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="info-assure"
                          >
                            All The Information I Have Entered Is Correct.
                          </label>
                        </div>
                      </div>
                      <div className="nk-kycfm-action pt-2">
                        {/* <Button
                        type="submit"
                        className="btn btn-sm"
                        color="primary"
                      >
                        Submit
                      </Button> */}
                        {loading ? (
                          <Button className="btn btn-sm" color="primary">
                            <Spinner size="sm" />
                            Please wait
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-sm"
                            type="submit"
                            color="primary"
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </Card>
            </Block>
          ) : (
            <div className="nk-kycfm-title">
              {/* <h5 className="title">Personal Details</h5> */}
              <p className="sub-title">
                Your Bank documents is submitted and verification is :{" "}
                <b>
                  {bankData && bankData.isVerified === false
                    ? "InProgress"
                    : "Verified"}
                </b>
              </p>
              {/* Thank you for your patientence */}So please wait till verified
              at our end.
            </div>
          )}
        </div>
      </Content>
    </>
  );
};

export default UpdateBankData;

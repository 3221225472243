/* eslint-disable no-unused-vars */
import { PaginationComponent, DataTable } from "../../../components/Component";
import { isoToHumanRedable } from "../../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { userState } from "../../../atom";
import { useRecoilState } from "recoil";
import axios from "axios";

const Usertable = ({ props }) => {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useRecoilState(userState);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const userId = userData && userData._id
  // console.log(userData);


  const API_BASE = "https://purnaagents.com/api";

  useEffect(() => {
    (async (xid) => {
      try {
        if (xid) {
          const resp = await toast.promise(
            axios({
              method: "GET",
              withCredentials: true,
              url: API_BASE + `/directReferral/${xid}`,
            }),
            {
              pending: "Fetching Direct Referral",
              success: "Data Fetched",
              error: "failed 🤯",
            },
            {
              autoClose: 1000,
            }
          );

          setData(resp["data"]["data"]);
          props.getCount(resp.data.data.length);
          // console.log(resp["data"]["data"]);

          let active = resp.data.data.filter((e) => e.Status === "Active");
          props.getActiveCount(active.length);

          let inactive = resp.data.data.filter((e) => e.Status === "Deactive");
          props.getInactiveCount(inactive.length);
        } else {
          const resp = await toast.promise(
            axios({
              method: "GET",
              withCredentials: true,
              url: API_BASE + `/directReferral/${userId}`,
            }),
            {
              pending: "Fetching Direct Referral",
              success: "Data Fetched",
              error: "failed 🤯",
            },
            {
              autoClose: 1000,
            }
          );

          setData(resp["data"]["data"]);
          props.getCount(resp.data.data.length);
          // console.log(resp["data"]["data"]);

          let active = resp.data.data.filter((e) => e.userStatus === "Active");
          props.getActiveCount(active.length);

          let inactive = resp.data.data.filter(
            (e) => e.userStatus === ("Suspend" || "InActive")
          );
          props.getInactiveCount(inactive.length);
        }
      } catch (error) {
        console.log(error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData]);

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <ToastContainer />
      <DataTable className="card-stretch this-card-sucks-1">
        <table className="table table-hover table-bordered  table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              {/* <th scope="col">Member ID</th> */}
              <th scope="col">Username</th>
              <th scope="col">Joining Date</th>
              <th scope="col">Active Date</th>
              <th scope="col">Invested Amt</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{e.userName}</td>
                {/* <td>{e.Cust_Name}</td> */}
                <td>{e.createdAt && isoToHumanRedable(e.createdAt)}</td>
                <td>{e.Paid_Date && isoToHumanRedable(e.Paid_Date)}</td>
                <td>{e.pdc}</td>
                <td
                  style={{
                    color: `${e.userStatus === "Active" ? "green" : "red"}`,
                  }}
                >
                  {e.userStatus}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="card-inner">
          {currentItems.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">Fetching Data Please Wait</span>
            </div>
          )}
        </div>
      </DataTable>
    </React.Fragment>
  );
};
export default Usertable;

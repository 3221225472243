/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useEffect } from "react";
import axios from "axios";
import { userState } from "../../../../atom";
import { useRecoilState } from "recoil";
import handleSignout from "../../../../pages/auth/HandleSignout";

const User = () => {
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [userData, setUserData] = useRecoilState(userState);
  const userId = localStorage.getItem("userId");
  const toggle = () => setOpen((prevState) => !prevState);

  useEffect(() => {
    (async () => {
      try {
        let resp = await axios({
          method: "get",
          url: `https://purnaagents.com/api/user`,
          withCredentials: true,
        });

        // console.log(resp);
        if (resp.data.status === true) {
          setUserData(resp["data"]["data"]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // console.log(userData);

  // const handleSignout = () => {
  //   localStorage.clear();
  // };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">User</div>
            <div className="user-name dropdown-indicator">
              {userData && userData["userName"]}
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>{userData && userData["userName"][0].toUpperCase()}</span>
            </div>
            <div className="user-info">
              <span className="lead-text">
                {customer && customer["Cust_Name"]}
              </span>
              <span className="sub-text">{userData && userData["email"]}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link="/user-profile-regular"
              icon="user-alt"
              onClick={toggle}
            >
              View Profile
            </LinkItem>
            <LinkItem
              link="/user-profile-setting"
              icon="setting-alt"
              onClick={toggle}
            >
              Account Setting
            </LinkItem>
            <LinkItem
              link="/user-profile-activity"
              icon="activity-alt"
              onClick={toggle}
            >
              Login Activity
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a
              href={`${process.env.PUBLIC_URL}/auth-login`}
              onClick={handleSignout}
            >
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button } from "reactstrap";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Block,
  PreviewAltCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import "./support.css";
import { Form, FormGroup, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
import SupportRecords from "./components/support/SupportRecords";
import { useRecoilState } from "recoil";
import { userState } from "../../atom";

const Support = () => {
  const [userData, setUserData] = useRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  const onFormSubmit = async (e) => {
    try {
    //   console.log(e);
      setLoading(true);
      let resp = await axios({
        method: "post",
        url: "https://purnaagents.com/api/support/supportTicket",
        withCredentials: true,
        data: {
          query: e["query"],
        },
      });
    //   console.log(resp["data"]);
      setLoading(false);
      toast.success(resp["data"]["message"], {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="My Downlines" />
      <ToastContainer />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <PreviewAltCard>
          <div
            class="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="right-side">
              <div class="topic-text">Send us a message</div>
              <p>
                If you have quries related to Transactions or Income or about
                anything else, you can send ous message from here. It's our
                pleasure to help you.
              </p>
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <FormGroup>
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Enter your name"
                      defaultValue={userData && userData.userName}
                      name="name"
                      //   ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )} */}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter your query"
                      name="query"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.query && (
                      <span className="invalid">{errors.query.message}</span>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  {loading ? (
                    <div class="button">
                      <Button color="primary">
                        {" "}
                        <Spinner size="sm" /> please wait{" "}
                      </Button>
                    </div>
                  ) : (
                    <div class="button">
                      <Button color="primary" type="submit">
                        {" "}
                        Send Now{" "}
                      </Button>
                    </div>
                  )}
                </FormGroup>
              </Form>
            </div>
          </div>
        </PreviewAltCard>
        <Block>
          <SupportRecords />
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Support;

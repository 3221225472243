/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CardTitle, Form, FormGroup, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import { toastError } from "../../utils/Utils";
import Head from "../../layout/head/Head";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import axios from "axios";
import { depositBalanceState, incomeBalanceState } from "../../atom";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
  Button,
  RSelect,
} from "../../components/Component";

const WithdrawReq = ({ ...props }) => {
  const [depositWallet, setDepositWallet] = useRecoilState(depositBalanceState);
  const [incomeWallet, setIncomeWallet] = useRecoilState(incomeBalanceState);
  const [totalWithdrwals, setTotalWithdrwals] = useState(null);
  const [bankdetails, setBankdetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(false);
  const { register, handleSubmit } = useForm();
  let history = useHistory();

  const API_BASE = "https://purnaagents.com/api";
  // console.log(depositWallet);

  const options = [
    // { value: "crypto", label: "WRS Address" },
    { value: "bankTr", label: "Bank Transfer" },
  ];

  const changeSelect = async (e) => {
    // console.log(e.value);
    setSelect(e.value);
  };

  const onFormSubmit = async (e) => {
    try {
      setLoading(true);
      let resp = await axios({
        method: "post",
        url: "https://purnaagents.com/api/withdrawRequest",
        data: {
          custId: localStorage.getItem("custId"),
          addr: e.addr,
          amt: e.amt,
          TransactionPassword: e.txPass,
        },
      });

      setLoading(false);

      toast.success(resp["data"]["message"], {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      let { message } = error["response"]["data"];
      setLoading(false);
      toastError(message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let getAllWIthdraws = await axios({
          method: "post",
          url: "https://purnaagents.com/api/getTotalWithdrwals",
          data: {
            custId: localStorage.getItem("custId"),
          },
        });

        console.log(getAllWIthdraws);

        if (getAllWIthdraws.data.data) {
          setTotalWithdrwals(getAllWIthdraws.data.data);
        }
      } catch (error) {
        console.log(error);
        let { message } = error["response"]["data"];
        alert(message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios({
          method: "get",
          url: API_BASE + "/wallet/allWallets",
          withCredentials: true,
        });
        // console.log(res.data.data[0]);

        if (res.data.status === true) {
          setDepositWallet(res.data.data[0]);
          setIncomeWallet(res.data.data[1]);
        }

        // let depWal = await axios({
        //   method: "post",
        //   url: "https://purnaagents.com/api/getDepositeBalance",
        //   data: {
        //     userId: localStorage.getItem("custId"),
        //   },
        // });

        // if (depWal["data"]) {
        //   setDepBal(depWal.data["depositeBalance"]);
        // }
      } catch (error) {
        toastError(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let isWithdrawlBlocked = await axios.post(
          "https://purnaagents.com/api/checkIfwithdrawlBlocked",
          {
            custID: localStorage.getItem("custId"),
          }
        );

        if (isWithdrawlBlocked["data"]["data"]["Status"] === 1) {
          alert("your withdawl is blocked");
          history.push("/");
        }
      } catch (error) {
        toastError(error.message);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          method: "get",
          url: API_BASE + `/kyc/bankDetails`,
          withCredentials: true,
        });

        // console.log(response.data);
        if (response.data.status === true) {
          setBankdetails(response["data"]["data"]);
        }
      } catch (error) {
        console.log(error);
        toastError(error.message);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Head title="Withdraw Request" />
      <ToastContainer />
      <Content>
        <BlockHead size="sm" className="mt-5">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h6">Withdraw Request</BlockTitle>
              <BlockDes className="text-soft">
                <p>Request a Withdraw</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Deposit</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-deposit"
                      text="Total Deposited"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {depositWallet && depositWallet.balance}{" "}
                    <span className="currency currency-usd">INR</span>
                  </span>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Income</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-withdraw"
                      text="Total Withdrawn"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {incomeWallet && incomeWallet.balance}
                    <span className="currency currency-usd">INR</span>
                  </span>
                </div>
              </PreviewAltCard>
            </Col>

            <Col lg="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Withdrawls</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-balance"
                      text="Total Balance"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {totalWithdrwals && totalWithdrwals.length
                      ? totalWithdrwals
                      : 0}{" "}
                    <span className="currency currency-usd">INR</span>
                  </span>
                </div>
              </PreviewAltCard>
            </Col>

            <Col lg="12">
              <PreviewAltCard>
                <CardTitle>Please Choose Your withdraw Method</CardTitle>
                <RSelect options={options} onChange={changeSelect} />
              </PreviewAltCard>
            </Col>

            {select && select === "crypto" ? (
              <Col className="m-auto" md={8}>
                <PreviewAltCard className="card-full this-card-sucks">
                  <div className="card-title">
                    <h6 className="subtitle">Make Withdrawl</h6>
                  </div>
                  <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <FormGroup>
                      <label className="form-label" htmlFor="name">
                        Enter Amount To Withdraw
                      </label>
                      <div className="form-control-wrap">
                        <input
                          className="form-control"
                          type="number"
                          id="name"
                          name="amt"
                          ref={register({ required: "This field is required" })}
                          placeholder="Enter Amount"
                        />
                      </div>
                    </FormGroup>
                    {/* <FormGroup>
                                            <label className="form-label" htmlFor="name">
                                                Enter Wallet Address
                                            </label>
                                            <div className="form-control-wrap">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="name"
                                                    name="addr"
                                                    ref={register({ required: "This field is required" })}
                                                    placeholder="Enter Wallet Address"
                                                />
                                            </div>
                                        </FormGroup> */}
                    {/* 
                                        <FormGroup>
                                            <label className="form-label" htmlFor="name">
                                                Enter OTP
                                            </label>
                                            <div className="form-control-wrap">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="otp"
                                                    name="otp"
                                                    ref={register({ required: "This field is required" })}
                                                    placeholder="Enter OTP"
                                                />
                                            </div>
                                        </FormGroup> */}

                    <FormGroup>
                      <label className="form-label" htmlFor="name">
                        Enter Transaction Password
                      </label>
                      <div className="form-control-wrap">
                        <input
                          className="form-control"
                          type="text"
                          id="txPass"
                          name="txPass"
                          ref={register({ required: "This field is required" })}
                          placeholder="Enter Transaction Password"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup>
                      {loading ? (
                        <Button color="primary">
                          <Spinner size="sm" /> Please wait
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Withdraw
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                </PreviewAltCard>
              </Col>
            ) : select === "bankTr" ? (
              <Col className="m-auto" md={12}>
                <PreviewAltCard className="card-full this-card-sucks">
                  <div className="card-title">
                    <h6 className="subtitle">Make Withdrawl</h6>
                  </div>
                  <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <FormGroup>
                      <label className="form-label" htmlFor="name">
                        Enter Amount To Withdraw
                      </label>
                      <div className="form-control-wrap">
                        <input
                          className="form-control"
                          type="number"
                          id="name"
                          name="amt"
                          ref={register({ required: "This field is required" })}
                          placeholder="Enter Amount"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-label" htmlFor="name">
                        <u>Bank Details</u>
                      </label>
                      <div class="card">
                        <ul class="data-list is-compact">
                          <li class="data-item">
                            <div class="data-col">
                              <div class="data-label">A/C No</div>
                              <div class="data-value">
                                {bankdetails && bankdetails["accountNumber"]}
                              </div>
                            </div>
                          </li>
                          <li class="data-item">
                            <div class="data-col">
                              <div class="data-label">Branch</div>
                              <div class="data-value">
                                {bankdetails && bankdetails["branchName"]}
                              </div>
                            </div>
                          </li>
                          <li class="data-item">
                            <div class="data-col">
                              <div class="data-label">IFSC Code</div>
                              <div class="data-value">
                                {bankdetails && bankdetails["ifscCode"]}
                              </div>
                            </div>
                          </li>
                          <li class="data-item">
                            <div class="data-col">
                              <div class="data-label">Bank Name</div>
                              <div class="data-value">
                                {bankdetails && bankdetails["bankName"]}
                              </div>
                            </div>
                          </li>
                          <li class="data-item">
                            <div class="data-col">
                              <div class="data-label">A/C Holder</div>
                              <div class="data-value">
                                {bankdetails &&
                                  bankdetails["accountHolderName"]}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      {loading ? (
                        <Button color="primary">
                          <Spinner size="sm" /> Please wait
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Withdraw
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                </PreviewAltCard>
              </Col>
            ) : null}

            {/* <Col xl="12" xxl="12">
                            <CardTitle> <h5>Withdraw History</h5> </CardTitle> 
                            <Card className="card-bordered card-full">
                                <table className="table table-hover table-borderless table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">First</th>
                                            <th scope="col">Last</th>
                                            <th scope="col">Handle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card> 
                        </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default WithdrawReq;

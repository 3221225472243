/* eslint-disable no-unused-vars */

import React from "react";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Block,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import LevelUserTable from "./LevelUserTable";
import { Row, Col } from "reactstrap";
import {
  PreviewAltCard,
  TooltipComponent,
} from "../../../components/Component";
import { useState } from "react";
import { isoToHumanRedable } from "../../../utils/Utils";

const LevelTeam = ({ ...props }) => {
  const [count, setCount] = useState(null);

  const getCount = (e) => {
    setCount(e);
  };

  return (
    <React.Fragment>
      <Head title="My Referrals" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>My Team</BlockTitle>
              <BlockDes className="text-soft">
                <p> select level to get levelwise team records </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row className="g-gs">
          <Col className="mb-3"></Col>
        </Row>
        <LevelUserTable props={getCount} />
      </Content>
      <Block></Block>
    </React.Fragment>
  );
};

export default LevelTeam;

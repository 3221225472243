/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Col, Card } from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const DashBoardStats = () => {
  const [dailystaking, setDailystaking] = useState(0);
  const [levelincome, setLevelincome] = useState(0);
  const [directIncome, setDirectincome] = useState(0);
  const [jackpotbonus, setJackpotbonus] = useState(0);

  const getFix = async () => {
    let levelIncomeBalance = await axios.post(
      "https://purnaagents.com/api/getLevelIncomeBalance",
      { custId: localStorage.getItem("custId") }
    );
    setLevelincome(levelIncomeBalance["data"]["balance"]);
  };

  useEffect(() => {
    (async () => {
      try {
        await getFix();

        let dailyStaking = await axios.post(
          "https://purnaagents.com/api/getDailyStakingIncome",
          { custId: localStorage.getItem("custId") }
        );
        setDailystaking(dailyStaking["data"]["balance"]);

        let directIncome = await axios.post(
          "https://purnaagents.com/api/getDirectIncomeBalance",
          { custId: localStorage.getItem("custId") }
        );
        setDirectincome(directIncome["data"]["balance"]);

        let getJackpotBonus = await axios.post(
          "https://purnaagents.com/api/getJackpotBonus",
          { custId: localStorage.getItem("custId") }
        );
        setJackpotbonus(getJackpotBonus["data"]["balance"]);
      } catch (error) {
        console.log(error["message"]);
      }
    })();
  }, []);

  const settings = {
    className: "slider-init",
    slidesToShow: 3,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 3 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    arrows: false,
    dots: true,
  };

  return (
    <Slider {...settings}>
      <Col className="px-0">
        <Card
          className="card-bordered"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="card-inner"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h5 className="card-title"> Daily Staking </h5>
            <span style={{ fontSize: "25px", fontWeight: "bolder" }}>
              {" "}
              {dailystaking && dailystaking > 0 ? dailystaking : 0}{" "}
            </span>
            <p className="card-text"> Earning From Daily Staking </p>
            <Link to="/dailyStakingHistory">
              {" "}
              <button
                className="btn btn-outline-light btn-round w-150px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>View Details</span>
              </button>
            </Link>
          </div>
        </Card>
      </Col>

      {/* <Col className="px-0">
                <Card className="card-bordered" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <div className="card-inner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <h5 className="card-title"> Direct Income </h5>
                        <span style={{ fontSize: '25px', fontWeight: 'bolder' }}> {directIncome && directIncome > 0 ? directIncome : 0} </span>
                        <p className="card-text"> Earning from Direct Income </p>
                        <Link to='/directIncome'><button className="btn btn-outline-light btn-round w-150px" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span>View Details</span></button></Link>
                    </div>
                </Card>
            </Col> */}

      <Col className="px-0">
        <Card
          className="card-bordered"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="card-inner"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h5 className="card-title"> Level Bonus </h5>
            <span style={{ fontSize: "25px", fontWeight: "bolder" }}>
              {" "}
              {levelincome && levelincome > 0
                ? Number(levelincome).toFixed(2)
                : 0}{" "}
            </span>
            <p className="card-text"> Earning From Level Bonus </p>
            <Link to="levelIncome">
              <button
                className="btn btn-outline-light btn-round w-150px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>View Details</span>
              </button>
            </Link>
          </div>
        </Card>
      </Col>

      {/* <Col className="px-0">
        <Card
          className="card-bordered"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="card-inner"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h5 className="card-title"> Other Bonus </h5>
            <span style={{ fontSize: "25px", fontWeight: "bolder" }}>
              {" "}
              {jackpotbonus && jackpotbonus > 0 ? jackpotbonus : 0}{" "}
            </span>
            <p className="card-text"> Earning From Other Bonus </p>
            <Link to="/jackpotBonusTable">
              <button
                className="btn btn-outline-light btn-round w-150px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>View Details</span>
              </button>
            </Link>
          </div>
        </Card>
      </Col> */}
    </Slider>
  );
};

export default DashBoardStats;


import React from "react";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, BlockDes, Block } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import JackpotBonusTable from "./tablesused/JackpotBonusTable";


const JackpotBonus = ({ ...props }) => {

    return (
        <React.Fragment>
            <Head title="My Referrals" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Jackpot Bonus</BlockTitle>
                            <BlockDes className="text-soft">
                                <p> Jackpot Bonus Income Details </p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <JackpotBonusTable />
            </Content>
            <Block>
            </Block>
        </React.Fragment>
    );
};

export default JackpotBonus;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import axios from "axios";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
} from "../../../components/Component";
import { useRecoilState } from "recoil";
import { userState } from "../../../atom";

const UserProfileRegularPage = ({ sm, updateSm, setProfileName }) => {
  const [modalTab, setModalTab] = useState("2");
  const [userInfo, setUserInfo] = useState(null);
  const [userData, setUserData] = useRecoilState(userState);

  // const [wrsaddress, setWrsaddress] = useState(null);
  // const [usdtbepaddress, setUsdtbepaddress] = useState(null);
  // console.log(userData);

  const [formData, setFormData] = useState({
    name: "",
    displayName: "",
    phone: "",
    country: "India",
    email: "",
  });

  const [modal, setModal] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     let resp = await axios({
  //       method: "post",
  //       url: "https://purnaagents.com/api/custById",
  //       data: {
  //         custId: localStorage.getItem("custId"),
  //       },
  //     });

  //     console.log(resp);
  //     setUserInfo(resp["data"]["data"]);
  //   })();
  // }, [modal]);

  useEffect(() => {
    setProfileName(formData.name);
  }, [formData, setProfileName]);

  const onInputChange = (e) => {
    setFormData({ [e.target.name]: e.target.value });
  };

  // const submitForm = async () => {
  //   try {
  //     let { data } = await axios.post(
  //       "https://purnaagents.com/api/updateAddress",
  //       { wrsaddress: wrsaddress, custId: localStorage.getItem("custId") }
  //     );
  //     alert("wallet Address Updated");
  //     setModal(false);
  //   } catch (error) {}
  // };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>Basic info, like your name, username, email and phone.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">User ID</span>
              <span className="data-value">{userData && userData._id}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div>

          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Full Name</span>
              <span className="data-value">
                {userData && userData.fullName}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>

          {/* <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Purna Address</span>
              <span className="data-value">
                {userInfo && userInfo.DASH_Address}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div> */}

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">User Name</span>
              <span className="data-value">
                {userData && userData.userName}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div>

          {/* <div className="data-item">
            <div className="data-col">
              <span className="data-label">Display Name</span>
              <span className="data-value">{userInfo && userInfo.Cust_Name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div> */}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{userData && userData.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Phone Number</span>
              <span className="data-value text-soft">
                {userData && userData.phoneNumber}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div>
        </div>
      </Block>

      <Modal
        isOpen={modal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => setModal(false)}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Update Profile</h5>
            <ul className="nk-nav nav nav-tabs">
              {/* <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  Personal
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#address"
                >
                  Address
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
                id="personal"
              >
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="full-name">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="name"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.name}
                        placeholder="Enter Full name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="display-name">
                        Display Name
                      </label>
                      <input
                        type="text"
                        id="display-name"
                        className="form-control"
                        name="displayName"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.displayName}
                        placeholder="Enter display name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="phone-no">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        id="phone-no"
                        className="form-control"
                        name="phone"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.phone}
                        placeholder="Phone Number"
                      />
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id="latest-sale"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="latest-sale"
                      >
                        Use full name to display{" "}
                      </label>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            // submitForm();
                          }}
                        >
                          Update Profile
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <div
                className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
                id="address"
              >
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="address-l1">
                        WRS Token Address
                      </label>
                      <input
                        type="text"
                        id="address-l1"
                        name="address"
                        // onChange={(e) => setWrsaddress(e.target.value)}
                        defaultValue={formData.address}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    {/* <FormGroup>
                      <label className="form-label" htmlFor="address-l2">
                        USDT BEP20 Address
                      </label>
                      <input
                        type="text"
                        id="address-l2"
                        name="address2"
                        onChange={(e) => setUsdtbepaddress(e.target.value)}
                        defaultValue={formData.address2}
                        className="form-control"
                      />
                    </FormGroup> */}
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          // onClick={() => submitForm()}
                        >
                          Update Address
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
